import { useEffect, useState } from "react";
import { SERVER_URL } from "../../config";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const [articleToggle, setArticleToggle] = useState(false);
  const activePath = window.location.pathname;
  const [dropdownToggle, setDropdownToggle] = useState(
    localStorage.getItem("dropdownToggleVal") ?? "Author"
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    dispatch({ type: "LOGOUT_STATUS_SUCCESS" });
    navigate("/");
  };

  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    navigate(path);
  };

  useEffect(() => {  
    if (activePath === "/dashboard") {
      setDropdownToggle("Author");
      localStorage.setItem("dropdownToggleVal", "Author");
      setArticleToggle(true);
    }
    if (dropdownToggle === "Author") {
      setArticleToggle(true);
    }
    if(activePath==='/myArticle/published'|| activePath==='/myArticle/rejected' ||  activePath==='/myArticle/submit' || activePath==='/myArticle/draft' || activePath==='/myArticle/approved')
      setArticleToggle(true);
    
    if (activePath === "/dashboard/society-editor") {
      setDropdownToggle("societyEditor");
      localStorage.setItem("dropdownToggleVal", "societyEditor");
      setArticleToggle(true);
    }
  }, []);

  function scrollTop()
  {window.scrollTo({top: 0, behavior: 'smooth'});
    setArticleToggle(true);
  }

  return (
    <div className="pageMenu text-initial">
      <h2 className="sideTitle">View Dashboard As</h2>
      <div className="customDrop sideDrop">
        <Dropdown>
          <Dropdown.Toggle variant="success">
            {dropdownToggle === "Author"
              ? "Author"
              : dropdownToggle === "PeerReviewer"
              ? "Peer Reviewer"
              : dropdownToggle === "PublishingEditor"
              ? "Publishing Editor"
              : dropdownToggle === "societyEditor"
              ? "Society Editor"
              : null}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              eventKey={"Author"}
              className={dropdownToggle === "Author" ? "active" : null}
              onClick={(e) => {
                setDropdownToggle("Author");
                localStorage.setItem("dropdownToggleVal", "Author");
                handleRedirect(e, "/dashboard");
              }}
            >
              Author
            </Dropdown.Item>
            <Dropdown.Item
              eventKey={"PeerReviewer"}
              className={dropdownToggle === "PeerReviewer" ? "active" : null}
              onClick={(e) => {
                setDropdownToggle("PeerReviewer");
                localStorage.setItem("dropdownToggleVal", "PeerReviewer");
                handleRedirect(e, "/dashboard/peer-reviewer");
              }}
            >
              Peer Reviewer
            </Dropdown.Item>
            <Dropdown.Item
              eventKey={"PublishingEditor"}
              className={
                dropdownToggle === "PublishingEditor" ? "active" : null
              }
              onClick={(e) => {
                setDropdownToggle("PublishingEditor");
                localStorage.setItem("dropdownToggleVal", "PublishingEditor");
                handleRedirect(e, "/dashboard/publishing-editor");
              }}
            >
              Publishing Editor
            </Dropdown.Item>
            <Dropdown.Item
              eventKey={"societyEditor"}
              className={
                dropdownToggle === "societyEditor" ? "active" : null
              }
              onClick={(e) => {
                setDropdownToggle("societyEditor");
                localStorage.setItem("dropdownToggleVal", "societyEditor");
                handleRedirect(e, "/dashboard/society-editor");
              }}
            >
              Society Editor
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="pageMenu-link mb-3">
        <ul>
          <li>
            <a
              className={activePath === "/myProfile" ? "active" : null}
              href="/myProfile"
              onClick={(e) => handleRedirect(e, "/myProfile")}
            >
              My Profile
            </a>
          </li>
           {dropdownToggle !== "societyEditor" && (
          <li>
            <a
              className={activePath === "/mudraKosh" ? "active" : null}
              href="/mudraKosh"
              onClick={(e) => handleRedirect(e, "/mudraKosh")}
            >
              <small></small>
              Mudra Kosh
            </a>
          </li>
          )}
        </ul>
      </div>

      {dropdownToggle === "PeerReviewer" ? (
        <>
          <h2 className="sideTitle">For Peer Review</h2>
          <div className="pageMenu-link">
            <ul>
              <li>
                <Link
                  to={{ pathname: "/peer-reviewer/requests" }}
                  state={"draft"}
                  className={
                    activePath === "/peer-reviewer/requests" ? "active" : null
                  }
                >
                  New Requests
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "/peer-reviewer/accepted" }}
                  state={"accepted"}
                  className={
                    activePath === "/peer-reviewer/accepted" ? "active" : null
                  }
                >
                  Accepted
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "/peer-reviewer/reviewed" }}
                  state={"reviewed"}
                  className={
                    activePath === "/peer-reviewer/reviewed" ? "active" : null
                  }
                >
                  Reviewed
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "/peer-reviewer/notAccepted" }}
                  state={"notAccepted"}
                  className={
                    activePath === "/peer-reviewer/notAccepted"
                      ? "active"
                      : null
                  }
                >
                  Not Accepted
                </Link>
              </li>
            </ul>
          </div>
        </>
      ) : dropdownToggle === "PublishingEditor" ? (
        <>
          <h2 className="sideTitle">For Review</h2>
          <div className="pageMenu-link ">
            <ul>
              <li>
                <Link
                  to={{ pathname: "/publishing-editor/requests" }}
                  state={"draft"}
                  className={
                    activePath === "/publishing-editor/requests"
                      ? "active"
                      : null
                  }
                >
                  Requests
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "/publishing-editor/accepted" }}
                  state={"accepted"}
                  className={
                    activePath === "/publishing-editor/accepted"
                      ? "active"
                      : null
                  }
                >
                  Accepted
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "/publishing-editor/review" }}
                  state={"sendForReview"}
                  className={
                    activePath === "/publishing-editor/review" ? "active" : null
                  }
                >
                  Sent for Review
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "/publishing-editor/peerReview" }}
                  state={"peerReview"}
                  className={
                    activePath === "/publishing-editor/peerReview"
                      ? "active"
                      : null
                  }
                >
                  Peer Review
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "/publishing-editor/approved" }}
                  state={"approved"}
                  className={
                    activePath === "/publishing-editor/approved"
                      ? "active"
                      : null
                  }
                >
                  Approved
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "/publishing-editor/revision" }}
                  state={"sentForRevision"}
                  className={
                    activePath === "/publishing-editor/revision"
                      ? "active"
                      : null
                  }
                >
                  Revision
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "/publishing-editor/rejected" }}
                  state={"rejected"}
                  className={
                    activePath === "/publishing-editor/rejected"
                      ? "active"
                      : null
                  }
                >
                  Rejected
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: "/publishing-editor/notAccepted" }}
                  state={"notAccepted"}
                  className={
                    activePath === "/publishing-editor/notAccepted"
                      ? "active"
                      : null
                  }
                >
                  Not Accepted
                </Link>
              </li>
            </ul>
          </div>
        </>
      ) : dropdownToggle === "societyEditor" ? (
        <>
        <div className="pageMenu-link pb-3">
         <h2  onClick={(e) => {
                setDropdownToggle("societyEditor");
                localStorage.setItem("dropdownToggleVal", "societyEditor");
                handleRedirect(e, "/dashboard/society-editor");
              }} className="sideTitle cursor-pointer" style={{color:'#be1e2d'}}>My Dashboard</h2>
              </div>
          <h2 className="sideTitle">For Society Editor</h2>

          <div className="pageMenu-link">
            <ul>
              <li>
                <Link
                  to={{ pathname: "/dashboard/society-editor/pr-appointment-history" }}
                  state={"accepted"}
                  className={
                    activePath === "/dashboard/society-editor/pr-appointment-history"
                      ? "active"
                      : null
                  }
                >
                  PR Appointment History
                </Link>
              </li>
               <li>
                <Link
                  to={{ pathname: "/dashboard/society-editor/pr-invited-list" }}
                  state={"accepted"}
                  className={
                    activePath === "/dashboard/society-editor/pr-invited-list"
                      ? "active"
                      : null
                  }
                >
               Invited Peer Reviews
                </Link>
              </li>
                <li>
                <Link
                  to={{ pathname: "/dashboard/society-editor/payment-bypass" }}
                  state={"accepted"}
                  className={
                    activePath === "/dashboard/society-editor/payment-bypass"
                      ? "active"
                      : null
                  }
                >
               Payment Bypass <span className="reddot_noti">2</span>
                </Link>
              </li>
                <li>
                <Link
                  to={{ pathname: "/dashboard/society-editor/special-deal-details" }}
                  state={"accepted"}
                  className={
                    activePath === "/dashboard/society-editor/special-deal-details"
                      ? "active"
                      : null
                  }
                >
                 Special Deal Details
                </Link>
              </li>
              {/* <li>
                <Link
                  // to={{ pathname: "/publishing-editor/review" }}
                  // state={"sendForReview"}
                  className={
                    activePath === "/publishing-editor/review" ? "active" : null
                  }
                >
                 New Messages <span className="reddot_noti">2</span>
                </Link>
              </li>  */}
            </ul>
          </div>
        </>
      ) : null}

      {dropdownToggle === "societyEditor"?null:(<>

      <h2
        className={
          (articleToggle) ? "sideTitle subnav active" : "sideTitle subnav"
        }
        onClick={() => setArticleToggle(!articleToggle)}
      >
        My Articles 
        <i></i>
      </h2>
      <div className={
          (articleToggle) ? "pageMenu-link " : "pageMenu-link manageSpace"
        } >
        {(articleToggle) && (
          <ul>
            <li  onClick={(e) => {scrollTop()}}>
              <Link
                to={{ pathname: "/myArticle/draft" }}
                state={"draft"}
                className={activePath === "/myArticle/draft" ? "active" : null}
              >
                Drafts
              </Link>
            </li>
            <li onClick={(e) => {scrollTop()}}>
              <Link
                to={{ pathname: "/myArticle/submit" }}
                state={"submit"}
                className={activePath === "/myArticle/submit" ? "active" : null}
              >
                Under Review
              </Link>
            </li>
            <li onClick={(e) => {scrollTop()}}>
              <Link
                to={{ pathname: "/myArticle/approved" }}
                state={"approved"}
                className={
                  activePath === "/myArticle/approved" ? "active" : null
                }
              >
                Approved
              </Link>
            </li>
            <li onClick={(e) => {scrollTop()}}>
              <Link
                to={{ pathname: "/myArticle/rejected" }}
                state={"rejected"}
                className={
                  activePath === "/myArticle/rejected" ? "active" : null
                }
              >
                Rejected
              </Link>
            </li>
            <li onClick={(e) => {scrollTop()}}>
              <Link
                to={{ pathname: "/myArticle/published" }}
                state={"published"}
                className={
                  activePath === "/myArticle/published" ? "active" : null
                }
              >
                Published
              </Link>
            </li>
          </ul>
        )}
      </div>
      </>)}
      <div className="pageMenu-link">
        <ul>
          <li>
            <Link
              className={activePath === "/readingList" ? "active" : null}
              to="/readingList"
              onClick={(e) => handleRedirect(e, "/readingList")}
            >
              Reading List
            </Link>
          </li>
          <li>
            <Link
              to="https://vikramshilaedu.in/resourcecentre"
              target="_blank"
            >
              Resource Centre
            </Link>
          </li>
          <li>
            <Link to="https://vikramshilaedu.in/GyanKosh" target="_blank">Blog</Link>
          </li>
          <li>
            <Link
              className={activePath === "/faq-akashganga" ? "active" : null}
              to="/readingList"
              onClick={(e) => handleRedirect(e, "/faq-akashganga")}
            >
              FAQs
            </Link>
          </li>
          <li>
          <Link
              className={activePath === "/need-help" ? "active" : null}
              to="/need-help"
            >
             Need Help?
            </Link>
            
          </li>
        </ul>
      </div>
      <div className="pageMenu-link">
        <ul>
          <li>
            <a onClick={() => handleLogout()}>
              <b className="icon-logout" /> Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
