import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import notyet from "../../assets/img/icons/notarticle.svg";
import active_check from "../../svg/verifiedCheck.svg";
import alertSvg from "../../assets/img/icons/alert_info.svg";
import grant from "../../assets/img/icons/grant.svg";
import active_info from "../../svg/active-info.svg";
import Dropdown from "react-bootstrap/Dropdown";
import dots from "../../assets/img/icons/dots.svg";
import downloadBlue from "../../assets/img/icons/downloadBlue.svg";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import { getDateFormat, downLoadFile, getNameFromUrl } from "../../utils";
import { useDispatch } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Confetti from "react-confetti";
import {
  getAllGrants,
  getGrantsDetails,
  applyForGrant,
  getGrantsDetailsByName,
  acceptRejectArticle,
} from "../../store/apiCalls/dashboard";
import { FetchSimiilarityReport } from "../../containers/autherFlow/submitArticle/queries";
import { toast } from "react-toastify";
import RequestAmmended from "../Modal/RequestAmmended";
import UpdateArticleRequest from "../Modal/UpdateArticleRequest";
import ReasonModal from "../../containers/autherFlow/prdashboard/ReasonModal";
import NeedHelpModal from "../Modal/NeedHelpModal";

const ArticleListing = ({
  articleData,
  activeTab,
  totalRecords,
  fetchArticleData,
  setUpdateTrigger,
  setAmendmentUpdateTrigger
}) => {

  // console.log(articleData,"---")
  const navigate = useNavigate();
  const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
  const dispatch = useDispatch();
  const limit = 20;
  const [nationality, setNationality] = useState("");
  const [articleId, setArticleId] = useState("");
  const [amendmentData, setAmendmentData] = useState("");
  const [noMoreAmendment, setNoMoreAmendment] = useState(false);
  const [showNotAccepted, setShowNotAccepted] = useState(false);
  const [reasonComments, setReasonComments] = useState("");
  const [showReject, setShowReject] = useState(false);
  const [reasonHandle, setReasonHandle] = useState("");
  const [rejectionArticleId, setRejectionArticleId] = useState("");
  const [articleName, setArticleName] = useState("");
  const [prReviewReport, setPrReviewReport] = useState({});
  const [reason, setReason] = useState({});
  const [authorName, setAuthorName] = useState("");
  const [amendmentArticleId, setAmendmentArticleId] = useState("");
  const [journalName, setJournalName] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [articleUpdateRequest, setArticleUpdateRequest] = useState(false);
  const [articleUpdateNewVersion, setArticleUpdateNewVersion] = useState(false);
  const [show, setShow] = useState(false);
  const [received, setReceived] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showWorkSaved, setShowWorkSaved] = useState({
    articleId: "",
    PID: "",
    status: "",
    finalPayment: "",
  });

  const [amendmentPopup, setamendmentPopup] = useState(false);
  const [amendmentSharingPopup, setamendmentSharingPopup] = useState(false);
  const [updateArticlePopup, setUpdateArticlePopup] = useState(false);
  const [updateSharingPopup, setUpdateSharingPopup] = useState(false);
  const [alreadyUpdatedPopup, setAlreadyUpdatedPopup] = useState(false);
  const [showNewArticleRequest, setShowNewArticleRequest] = useState(false);
  const [fundingStatus, setFundingStatus] = useState("");

  const handleShow = () => setShow(true);
  const [page, setPage] = useState(1);
  const [wordDecCount, setWordDecCount] = useState(0);
  const [showReadMore, setShowReadMore] = useState([]);
  const [grantDetail, setGrantDetail] = useState([]);
  const [availableGrants, setavailableGrants] = useState(false);
  const [grantData, setGrantData] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const handleClose = () => setShowDetail(false);
  const MAX_WORDS = 30;
  const [applyGrant, setapplyGrant] = useState(false);
  const [formData, setFormData] = useState({
    proposal: "",
    foundReceived: false,
  });
  const [formErrors, setFormErrors] = useState({
    proposal: "",
  });
  const [articleDetailData, setArticleDetailData] = useState({
    articleId: "",
    applicantName: "",
    article: "",
  });

  const sucessHandlerSimillarityReport = (articleDetails) => {
    if (articleDetails.status === 200) {
      downLoadFile(articleDetails.data.url, "report.pdf");
    }
    dispatch({ type: "SET_LOADER", payload: false });
  };
  const { mutate: downloadSimiliratyReport } = FetchSimiilarityReport(
    sucessHandlerSimillarityReport
  );
  const wordLimit = 300;
  const fetchAllGrantsData = () => {
    dispatch({ type: "SET_LOADER", payload: true });
    const fetchdata = async () => {
      const params = {
        status: "live",
        page: page,
        limit: limit,
      };
      return getAllGrants(headers, params);
    };

    fetchdata()
      .then((grantData) => {
        if (grantData.status === 200 && grantData?.data?.data) {
          dispatch({ type: "SET_LOADER", payload: false });
          setGrantData(grantData.data.data);
        }
      })
      .catch((err) => console.log(err));
    dispatch({ type: "SET_LOADER", payload: false });
  };
  const getGrantDetailReceived = (name) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const fetchdata = async () => {
      const params = {
        grantName: name,
      };
      return getGrantsDetailsByName(headers, params);
    };

    fetchdata()
      .then((grantDetailData) => {
        if (grantDetailData.status === 200 && grantDetailData?.data?.data) {
          dispatch({ type: "SET_LOADER", payload: false });
          setGrantDetail(grantDetailData?.data?.data);
          setReceived(true);
        }
      })
      .catch((err) => console.log(err));
    dispatch({ type: "SET_LOADER", payload: false });
  };
  const getGrantDetailByName = (name) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const fetchdata = async () => {
      const params = {
        grantName: name,
      };
      return getGrantsDetailsByName(headers, params);
    };

    fetchdata()
      .then((grantDetailData) => {
        if (grantDetailData.status === 200 && grantDetailData?.data?.data) {
          dispatch({ type: "SET_LOADER", payload: false });
          setGrantDetail(grantDetailData?.data?.data);
          setShowDetail(true);
          setShowButton(false);
        }
      })
      .catch((err) => console.log(err));
    dispatch({ type: "SET_LOADER", payload: false });
  };
  const getGrantDetail = (id) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const fetchdata = async () => {
      const params = {
        grantId: id,
      };
      return getGrantsDetails(headers, params);
    };

    fetchdata()
      .then((grantDetailData) => {
        if (grantDetailData.status === 200 && grantDetailData?.data?.data) {
          dispatch({ type: "SET_LOADER", payload: false });
          setGrantDetail(grantDetailData?.data?.data);
          setavailableGrants(false);
          setShowDetail(true);
        }
      })
      .catch((err) => console.log(err));
    dispatch({ type: "SET_LOADER", payload: false });
  };
  const applyForGrants = (formData) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const fetchdata = async () => {
      const params = {
        articleId: articleDetailData?.articleId,
        grantName: grantDetail?.grantName,
        grantId: grantDetail?._id,
        applicantName: articleDetailData?.applicantName,
        article: articleDetailData?.article,
        grantValue: grantDetail?.grantValue,
        proposal: formData?.proposal,
        fundindRecevied: formData?.foundReceived,
      };
      return applyForGrant(headers, params);
    };

    fetchdata()
      .then((grantApplyData) => {
        if (grantApplyData?.data?.sucess === true) {
          dispatch({ type: "SET_LOADER", payload: false });
          setapplyGrant(false);
          setShow(true);
        }
      })
      .catch((err) => console.log(err));
    dispatch({ type: "SET_LOADER", payload: false });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setFundingStatus("");
      applyForGrants(formData);
    }
  };
  const validateForm = () => {
    let valid = true;
    const errors = {};
    if (!formData?.proposal) {
      errors.proposal = "This field is required";
      valid = false;
    } else if (formData.proposal.split(" ").length > wordLimit) {
      errors.proposal = `Description should not exceed ${wordLimit} words`;
      valid = false;
    }
    setFormErrors(errors);
    return valid;
  };
  const handleChangeCheckbox = (event) => {
    const { name, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };
  const handleChangeProposal = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setWordDecCount(value.trim().split(/\s+/).length);
  };

  const getSimillarityReport = async (indexId, d_key) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const queryParams = {
      params: { similarityCheckId: indexId, d_key },
      headers: headers,
    };
    downloadSimiliratyReport(queryParams);
  };

  const handleReadMore = (idx) => {
    let result = [...showReadMore];
    result[idx] = !result[idx];
    setShowReadMore(result);
  };

  const handlePublishArticle = async (_id, peId, status, finalPayment) => {
    setShowWorkSaved({
      articleId: _id,
      PID: peId,
      status: true,
      finalPayment: finalPayment,
    });
  };

  const publishArticleAPICall = async () => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: articleId,
      peStatus: "published",
      peId: showWorkSaved?.PID,
    };
    const response = await acceptRejectArticle(headers, params);
    if (response?.status === 200) {
      setShowWorkSaved({
        articleId: "",
        PID: "",
        status: false,
        finalPayment: "",
      });
      navigate("/myArticle/published", { state: "published" });
    } else {
      return toast.error("Something went wrong. Please contact with admin");
    }
  };

  useEffect(() => {
    let myArray = new Array(articleData?.length).fill(false);
    setShowReadMore(myArray);
  }, [articleData]);

  useEffect(() => {
    fetchAllGrantsData();
  }, [page]);

  const handleArticleUpdate = (article) => {
    return setArticleUpdateRequest(article);
  };

  const handleArticleNewRequest = (article) => {
    return setArticleUpdateNewVersion(article);
  };

  const handleRedirect = (e, path) => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };

  const downloadContract = (url) => {
    if (url) {
      let filename = getNameFromUrl(url);
      if (filename.includes("?")) {
        let finalFilename = filename?.split("?")[0];
        downLoadFile(url, `${finalFilename}`);
      } else {
        downLoadFile(url, `${filename}`);
      }
    }
  };
  // console.log('articleData',articleData);

  return (
    <>
      <div className="contentListBox">
        {articleData && articleData.length > 0 ? (
          articleData?.map((article, index) => {
            const specialCharPattern = /[!@#$%^*(),.?;":{}|\u00a0]/g;
            const contentWithoutSpecialChars = () => {
              if (activeTab === "draft" || activeTab === "submit") {
                return article?.abstract?.replace(specialCharPattern, "");
              } else {
                return article?.article?.[0]?.abstract?.replace(
                  specialCharPattern,
                  ""
                );
              }
            };
            return (
              <div className="contentList">
                {activeTab === "draft" && (
                  <>
                 
                    <div className="draftArticleWrap">
                      {
                      
                      article?.plagirismCheck === "underProcess" ? (
                        <h2 className="progressText">
                          SIMILARITY INDEX CHECK IN PROGRESS
                        </h2>
                      ) : article?.plagirismCheck === "Done" &&
                        (article?.similarityCheckPercentage ||
                          article?.similarityCheckPercentage == 0) ? (
                        <h2
                          className={
                            article?.similarityCheckPercentage > 15
                              ? "failedText"
                              : "successText"
                          }
                        >
                          {article?.similarityCheckPercentage > 15
                            ? "HIGH SIMILARITY MATCHING"
                            : "SUCCESSFUL SIMILARITY INDEX CHECK"}{" "}
                          - {article?.similarityCheckPercentage}%
                          {article?.similarityCheckPercentage > 15 ? (
                            <div className="customTool">
                              {["bottom"].map((placement) => (
                                <OverlayTrigger
                                  key={placement}
                                  placement={placement}
                                  overlay={
                                    <Popover>
                                      <Popover.Body>
                                        <div className="popoverToolWrap">
                                          <p>
                                            The indicated percentage score shows
                                            the similarity index of your
                                            article. If it exceeds 15%, you will
                                            need to rework the article, to bring
                                            down this number below 15% before
                                            the article can be further
                                            processed. Incase of further
                                            assistance, please write to{" "}
                                            <a href="mailto:contactus@vikramshilaedu.in">
                                              contactus@vikramshilaedu.in
                                            </a>
                                          </p>
                                        </div>
                                      </Popover.Body>
                                    </Popover>
                                  }
                                >
                                  <img
                                    className="pe-cursor ps-1"
                                    src={active_info}
                                    alt="no img"
                                  />
                                </OverlayTrigger>
                              ))}
                            </div>
                          ) : (
                            <img src={active_check} alt="active-check" />
                          )}
                        </h2>
                      ) : null}
                    </div>
                    <div className="contentListHead">
                      <div className="timeList">
                        <span>
                          <strong>Created On:&nbsp;</strong>
                          {article?.createdAt
                            ? getDateFormat(article?.createdAt)
                            : "--"}
                        </span>
                        <span>
                          <strong>Last Edited: &nbsp;</strong>
                          {article?.updatedAt
                            ? getDateFormat(article?.updatedAt)
                            : "--"}
                        </span>
                        
                      </div>
                    {article?.submissionDetail?.journalType === "6454a6390d88e62fa10503bf"?<>
                      <span className="t-type mr-0" style={{maxWidth:'465px',lineHeight:'24px'}}>
                        {article?.paymentBySociety?.apcPayBySociety && !article?.paymentBySociety?.apcPayHaveCoupon && (<>
                        <p className="font-bold pb-1 " >{article?.initialPayment === true?<>The Society has made the payment <span className="red-text blink_me">*</span></>:"Submission Journey in Progress"}</p>
                        </>)}
                        <p className="fs-5" style={{color:'#5a5a5a'}}>{article?.plagirismCheck === "notDone"
                          ? article?.articleStatus === "sentForRevision" ? "Sent For Revision" : (article?.paymentBySociety?.apcPayBySociety && !article?.paymentBySociety?.apcPayHaveCoupon && article?.initialPayment)? "Submission Journey is Live. Complete the submission journey to start writing the article." :article?.articleStatus
                          : null}
                          </p>
                      </span>
                      </>:<>
                      <span className="t-type mr-0">
                        {article?.plagirismCheck === "notDone"
                          ? article?.articleStatus === "sentForRevision" ? "Sent For Revision" : article?.articleStatus
                          : null}
                      </span>
                      </>}
                    </div>
                  </>
                )}
                {activeTab === "submit" && (
                  <div className="contentListHead">
                    <div className="timeList">
                      <span>
                        <strong>Submitted On: &nbsp;</strong>
                        {article?.createdAt
                          ? getDateFormat(article?.createdAt)
                          : "--"}
                      </span>
                      
                    </div>
                    <span className="t-type mr-0">
                      {article?.articleStatus === "sentForRevision" ? "Sent For Revision" : article?.articleStatus}
                    </span>
                  </div>
                )}
                {activeTab === "approved" && (
                  <>
                    <div className="contentListHead">
                      <div className="timeList">
                        <span>
                          <strong>Approved On:</strong>{" "}
                          {getDateFormat(article?.updatedAt)}
                        </span>
                        
                      </div>
                    </div>
                    {article?.article?.[0]?.grantDetails?.grantStatus ===
                      "reject" &&
                      article?.grant?.[0]?.isDeleted === false && (
                        <span className="textDetailsWrap textRed">
                          Grant Application Not Accepted{" "}
                          <b
                            onClick={() => [
                              setShowReject(true),
                              setReason(
                                article?.article?.[0]?.grantDetails?.reason
                              ),
                            ]}
                          >
                            Reason
                          </b>
                        </span>
                      )}
                    {article?.grant?.[0]?.isDeleted && (
                      <span className="textDetailsWrap textRed">
                        Grant Application Not Accepted{" "}
                        <b
                          onClick={() => [
                            setShowReject(true),
                            setReason("This Grant has been deleted"),
                          ]}
                        >
                          Reason
                        </b>
                      </span>
                    )}
                    {article?.article?.[0]?.grantDetails?.grantStatus ===
                      "approved" &&
                      article?.grant?.[0]?.isDeleted === false && (
                        <span className="textDetailsWrap textGreen">
                          Grant Application Accepted
                        </span>
                      )}
                    {article?.article?.[0]?.grantDetails?.grantStatus ===
                      "pending" &&
                      article?.grant?.[0]?.isDeleted === false && (
                        <span className="textDetailsWrap textOrange">
                          Applied For Grant
                        </span>
                      )}
                  </>
                )}
                {activeTab === "rejected" && (
                  <div className="contentListHead">
                    <div className="timeList">
                      <span>
                        <strong>Rejected On:</strong>{" "}
                        {getDateFormat(article?.updatedAt)}
                      </span>
                      
                    </div>
                  </div>
                )}
                {activeTab === "published" && (
                  <div className="contentListHead">
                    <div className="timeList">
                      <span>
                        <strong>Published On:</strong>{" "}
                        {getDateFormat(article?.createdAt)}
                      </span>
                      
                    </div>
                    <div className="customDrop moreCustom">
                      <Dropdown>
                        <Dropdown.Toggle variant="success">
                          <img src={dots} alt="#" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setamendmentPopup(true);
                              setAmendmentArticleId(article?.articleId);
                              setArticleName(article?.article?.[0]?.title);
                              setAuthorName(
                                article?.article?.[0]?.authorDetails
                                  ?.author?.[0]?.fullName
                              );
                              setAmendmentData(
                                article?.article?.[0]?.amendmentData
                              );
                              setJournalName(
                                article?.article?.[0]?.submissionDetail
                                  ?.journalName
                              );
                              setSubjectName(
                                article?.subjectDetail?.[0]?.subjectName
                              );
                              setNoMoreAmendment(true);
                              setNationality(article?.user?.[0]?.nationality)
                            }}
                          >
                            Add Amendment
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              handleArticleUpdate(
                                article?.article?.[0]?.updateRequest
                              );

                              handleArticleNewRequest(
                                article?.article?.[0]?.updateRequestApprove
                              );

                              {
                                article?.article?.[0]?.updateRequest &&
                                article?.article?.[0]?.updateRequestApprove
                                  ? setShowNewArticleRequest(true)
                                  : article?.article?.[0]?.updateRequest
                                  ? setAlreadyUpdatedPopup(true)
                                  : setUpdateArticlePopup(true);
                              }
                              setUpdateArticlePopup(true);
                              setAmendmentArticleId(article?.articleId);
                              setArticleName(article?.article?.[0]?.title);
                              setAuthorName(
                                article?.article?.[0]?.authorDetails
                                  ?.author?.[0]?.fullName
                              );
                              setJournalName(
                                article?.article?.[0]?.submissionDetail
                                  ?.journalName
                              );
                              setSubjectName(
                                article?.subjectDetail?.[0]?.subjectName
                              );
                            }}
                          >
                            Update Article
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => {
                              downloadContract(
                                article?.article?.[0]?.articleInvoice
                              );
                            }}
                            className={!article?.article?.[0]?.articleInvoice && "opacity-5"}
                          >
                            Article Invoice
                            <img src={downloadBlue} alt="#" />
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              downloadContract(
                                article?.article?.[0]?.contractLink
                              );
                            }}
                          >
                            Article Contract
                            <img src={downloadBlue} alt="#" />
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                )}
                <div className="contentListMidd">
                  {article?.typeOfArticle?.articleName && (
                    <div className="t-tag">
                      {article?.typeOfArticle?.articleName}
                    </div>
                  )}
                  <h2>
                    <Link
                      to={{
                        pathname:
                          activeTab !== "published"
                            ? `/dashboard/article/${article?._id}/review-article`
                            : null,
                      }}
                      state={{
                        _id:
                          activeTab === "draft" || activeTab === "submit"
                            ? article?._id
                            : article?.articleId,
                      }}
                      onClick={
                        activeTab === "published"
                          ? (e) =>
                              handleRedirect(
                                e,
                                `/viewPublishedArticle/${article?.articleId}`
                              )
                          : null
                      }
                    >
                      {activeTab === "draft" || activeTab === "submit"
                        ? article?.title
                        : article?.article?.[0]?.title}
                    </Link>
                  </h2>
                  {contentWithoutSpecialChars()?.split(" ").length >
                  MAX_WORDS ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          contentWithoutSpecialChars()
                            ?.split(" ")
                            ?.slice(0, MAX_WORDS)
                            .join(" ") + "....",
                      }}
                    ></p>
                  ) : (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: contentWithoutSpecialChars()
                          ?.split(" ")
                          ?.slice(0, MAX_WORDS)
                          .join(" "),
                      }}
                    ></p>
                  )}
                </div>
                {activeTab === "approved" && (
                  <div className="contentListFoot contentListTwo">
                    <div className="footTag">
                      {article?.typeofarticles[0]?.articleName && (
                        <span className="t-tag">
                          {article?.typeofarticles[0]?.articleName}
                        </span>
                      )}

                      {article?.subjectDetail[0]?.subjectName && (
                        <span className="t-tag">
                          {article?.subjectDetail[0]?.subjectName}
                        </span>
                      )}

                      {article?.specializations[0]?.specialization && (
                        <span className="t-tag">
                          {article?.specializations[0]?.specialization}
                        </span>
                      )}

                      {article?.article?.[0]?.submissionDetail?.journalName && (
                        <div className="t-tag-journalWrap d-flex justify-content-center">
                          <span className="t-tag t-tag-journal">
                            {
                              article?.article?.[0]?.submissionDetail
                                ?.journalName
                            }
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="footAction">
                      {(article?.user?.[0]?.nationality === "withInIndia") && (
                        article?.article?.[0]?.finalPayment === false &&
                        article?.article?.[0]?.grantDetails?.grantStatus ===
                          "pending" &&
                        article?.grant?.[0]?.isDeleted === false) && (
                          <a
                            href="javascript:void(0)"
                            className="button-link"
                            onClick={() => [
                              getGrantDetailByName(
                                article?.article?.[0]?.grantDetails?.grantName
                              ),
                              setShowButton(false),
                            ]}
                          >
                            Grant Details
                          </a>
                        )}
                      {(article?.user?.[0]?.nationality === "withInIndia") && (
                        article?.article?.[0]?.finalPayment === false &&
                        article?.article?.[0]?.grantDetails?.grantStatus ===
                          "approved" &&
                        article?.grant?.[0]?.isDeleted === false) && (
                          <a
                            href="javascript:void(0)"
                            className="button-link"
                            onClick={() => [
                              getGrantDetailReceived(
                                article?.article?.[0]?.grantDetails?.grantName
                              ),
                              setArticleDetailData({
                                articleId: article?.article?.[0]?._id,
                                applicantName: article?.user[0]?.fullName,
                                article: article?.article?.[0]?.title,
                              }),
                            ]}
                          >
                            Grant Received
                          </a>
                        )}
                      
                      {(article?.user?.[0]?.nationality === "withInIndia") && (
                        (article?.article?.[0]?.finalPayment === false &&
                        article?.article?.[0]?.grantDetails?.grantStatus ===
                          "notApplied") ||
                        article?.article?.[0]?.grantDetails?.grantStatus ===
                          "reject" ||
                        article?.grant?.[0]?.isDeleted === true) && (
                        <a
                          href="javascript:void(0)"
                          className="button-link"
                          onClick={() => [
                            setavailableGrants(true),
                            setShowButton(true),
                            setFundingStatus(
                              article?.article?.[0]?.fundingDetails
                                ?.fundingInformation
                            ),
                            setArticleDetailData({
                              articleId: article?.article?.[0]?._id,
                              applicantName: article?.user[0]?.fullName,
                              article: article?.article?.[0]?.title,
                            }),
                          ]}
                        >
                          Apply for Grant
                        </a>
                      )}

                      {article?.prReview &&
                        article?.article?.[0]?.finalPayment === false && (
                          <Link
                            to={{
                              pathname: `/dashboard/article/${article?._id}/view-report`,
                            }}
                            state={{
                              _id: article?._id,
                              rejectionArticleId: article?.articleId,
                              status: activeTab,
                              title: article?.article?.[0]?.title,
                              createdAt: article?.createdAt,
                              updatedAt: article?.updatedAt,
                              reasonHandle: article?.peRejectedReason,
                              userType: "Author",
                            }}
                            className="button-link"
                          >
                            VIEW REPORT
                          </Link>
                        )}
                      <Link
                        to="#"
                        className="button button-primary"
                        onClick={() => {
                          handlePublishArticle(
                            article?.articleId,
                            article?.peId?.[0],
                            "published",
                            article?.article?.[0]?.finalPayment
                          ),
                            setArticleId(article?._id);
                        }}
                      >
                        Publish Now
                      </Link>
                    </div>
                  </div>
                )}
                {activeTab === "draft" && (
                  <div className="contentListFoot contentListTwo">
                    <div className="footTag">
                      {article?.typeofarticles[0]?.articleName && (
                        <span className="t-tag">
                          {article?.typeofarticles[0]?.articleName}
                        </span>
                      )}

                      {article?.subjectDetail[0]?.subjectName && (
                        <span className="t-tag">
                          {article?.subjectDetail[0]?.subjectName}
                        </span>
                      )}

                      {article?.specializations[0]?.specialization && (
                        <span className="t-tag">
                          {article?.specializations[0]?.specialization}
                        </span>
                      )}

                      {article?.submissionDetail?.journalName && (
                        <div className="t-tag-journalWrap d-flex justify-content-center">
                          <span className="t-tag t-tag-journal">
                            {article?.submissionDetail?.journalName}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="footAction">
                      {article.plagirismCheck === "Done" &&
                      article?.plagirismCheck !== "notDone" &&
                      article?.plagirismCheck !== "underProcess" &&
                      article?.similarityCheckPercentage > "0" &&
                      article?.articleStatus !== "First Revision" ? (
                        <Link
                          onClick={() =>
                            getSimillarityReport(article.similarityCheckId, article?.d_key)
                          }
                          className="button-link"
                        >
                          View Report
                        </Link>
                      ) : (
                        article?.articleStatus === "First Revision" && (
                          <Link
                            to={{
                              pathname: `/dashboard/article/${article?._id}/view-report`,
                            }}
                            state={{
                              _id: article?._id,
                              rejectionArticleId: article?._id,
                              articleStatus: "First Revision",
                              userType: "Author",
                            }}
                            className="button-link"
                          >
                            VIEW REPORT
                          </Link>
                        )
                      )}
                      
                      
                       {article?.plagirismCheck === "underProcess" || (article?.paymentBySociety?.apcPayHaveCoupon === false && article?.paymentBySociety?.apcPayBySociety === true && article?.initialPayment === false) ||
                    (article?.plagirismCheck === "done" && article?.similarityCheckPercentage < 15) ||
                    (article?.stage === "adminctrl" && 
                      article?.similarityCheckPercentage === undefined ) ? null : (
                      <div>
                        <Link
                          to={`/dashboard/edit-article/${article?._id}`}
                          className="button button-primary"
                        >
                         
                          Edit Article
                        </Link>
                      </div>
                    )}
                    {/* {article?.plagirismCheck === "underProcess" ? null : (
                        <Link
                          to={`/dashboard/edit-article/${
                            article?.articleId ?? article?._id
                          }`}
                          className="button button-primary mr-0"
                        >
                          Edit Article
                        </Link>
                      )} */}

                      {article?.similarityCheckPercentage > 15 ? (
                        <div className="ml-4">
                          <button className="button-link" onClick={() => setShowHelpModal(true)}>Need Help?</button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
                {activeTab === "rejected" && (
                  <div className="contentListFoot contentListTwo">
                    <div className="footTag">
                      {article?.typeofarticles[0]?.articleName && (
                        <span className="t-tag">
                          {article?.typeofarticles[0]?.articleName}
                        </span>
                      )}

                      {article?.subjectDetail[0]?.subjectName && (
                        <span className="t-tag">
                          {article?.subjectDetail[0]?.subjectName}
                        </span>
                      )}

                      {article?.specializations[0]?.specialization && (
                        <span className="t-tag">
                          {article?.specializations[0]?.specialization}
                        </span>
                      )}

                      {article?.article?.[0]?.submissionDetail?.journalName && (
                        <div className="t-tag-journalWrap d-flex justify-content-center">
                          <span className="t-tag t-tag-journal">
                            {
                              article?.article?.[0]?.submissionDetail
                                ?.journalName
                            }
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="footAction">
                      
                      <Link
                        to="#"
                        className="button button-primary"
                        onClick={() => {
                          setShowNotAccepted(true);
                          setArticleId(article?._id);
                          setRejectionArticleId(article?.articleId);
                          setReasonHandle(article?.peRejectedReason);
                          setReasonComments(
                            article?.peRejectedReasonAdditionalComment
                          );
                          setPrReviewReport(article?.prReview);
                        }}
                      >
                        VIEW REASON
                      </Link>
                    </div>
                  </div>
                )}
                {activeTab === "submit" && (
                  <div className="contentListFoot contentListTwo">
                    <div className="footTag">
                      {article?.typeofarticles[0]?.articleName && (
                        <span className="t-tag">
                          {article?.typeofarticles[0]?.articleName}
                        </span>
                      )}

                      {article?.subjectDetail[0]?.subjectName && (
                        <span className="t-tag">
                          {article?.subjectDetail[0]?.subjectName}
                        </span>
                      )}

                      {article?.specializations[0]?.specialization && (
                        <span className="t-tag">
                          {article?.specializations[0]?.specialization}
                        </span>
                      )}

                      {article?.submissionDetail?.journalName && (
                        <div className="t-tag-journalWrap d-flex justify-content-center">
                          <span className="t-tag t-tag-journal">
                            {article?.submissionDetail?.journalName}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {activeTab === "published" && (
                  <div className="contentListFoot contentListTwo">
                    <div className="footTag">
                      {article?.typeofarticles[0]?.articleName && (
                        <span className="t-tag">
                          {article?.typeofarticles[0]?.articleName}
                        </span>
                      )}

                      {article?.subjectDetail[0]?.subjectName && (
                        <span className="t-tag">
                          {article?.subjectDetail[0]?.subjectName}
                        </span>
                      )}

                      {article?.specializations[0]?.specialization && (
                        <span className="t-tag">
                          {article?.specializations[0]?.specialization}
                        </span>
                      )}

                      {article?.article?.[0]?.submissionDetail?.journalName && (
                        <div className="t-tag-journalWrap d-flex justify-content-center">
                          <span className="t-tag t-tag-journal">
                            {
                              article?.article?.[0]?.submissionDetail
                                ?.journalName
                            }
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="tabContentWrap">
            <div className="notArticle">
              <figure>
                <img src={notyet} alt="No Article" />
              </figure>
              {activeTab === "draft" ? (
                <div className="au_draft">
                  <h2>You don’t have any drafts waiting!</h2>

                  <p>
                    It is time to write a new research article
                    <span>
                      We await strokes of your brilliance and colours of your
                      ideas, shaping the course of knowledge.
                    </span>
                  </p>
                </div>
              ) : activeTab === "submit" ? (
                <div className="au_submit">
                  <h2>No articles under review!</h2>
                  <p>
                    Yep, you heard it right. It's like a clean slate in there!
                    <span>
                      Think of this as a chance to recharge those brain cells
                      with our music{" "}
                      <u>
                        <b>playlists</b>
                      </u>
                      , or read up new ideas on our{" "}
                      <u>
                        <b>blog</b>
                      </u>
                      ...
                    </span>
                  </p>
                </div>
              ) : activeTab === "approved" ? (
                <div className="au_approved">
                  <h2>Access All approved articles here</h2>
                  <p>
                    All your published content at one central location
                    <span>
                      {" "}
                      Thank you for choosing us as your Publishing Partner. We
                      look forward to seeing your continued success...
                    </span>
                  </p>
                </div>
              ) : activeTab === "rejected" ? (
                <div className="au_rejected">
                  <h2>No rejected articles here!</h2>
                  <p>
                    Articles that do not get accepted for publishing will show
                    here
                    <span>
                      {" "}
                      Rejection is an integral part of your growth as a
                      researcher! Don't be afraid to embrace it, learn from it,
                      and come out even stronger! Happy Writing...
                    </span>
                  </p>
                </div>
              ) : activeTab === "published" ? (
                <div className="au_published">
                  <h2>No Published articles are home yet!</h2>
                  <p>
                    Your personal library showcasing your valuable contributions
                    <span>
                      {" "}
                      We take pride in your work and will always ensure it is
                      within your reach. It is a testament to your hard work and
                      dedication...😊
                    </span>
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>

      {activeTab === "draft" && totalRecords > 1 && (
        <div className="btnWrap mb-5 mt-4 text-center">
          <Link
            to={{ pathname: "/myArticle/draft" }}
            state={"draft"}
            className="button-border"
            onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            VIEW ALL DRAFTS
          </Link>
        </div>
      )}

      {activeTab === "submit" && totalRecords > 1 && (
        <div className="btnWrap mb-5 mt-4 text-center">
          <Link
            to={{ pathname: "/myArticle/submit" }}
            state={"submit"}
            className="button-border"
            onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            VIEW ALL UNDER REVIEW
          </Link>
        </div>
      )}

      {activeTab === "approved" && totalRecords > 1 && (
        <div className="btnWrap mb-5 mt-4 text-center">
          <Link
            to={{ pathname: "/myArticle/approved" }}
            state={"approved"}
            className="button-border"
            onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            VIEW ALL APPROVED
          </Link>
        </div>
      )}

      {activeTab === "rejected" && totalRecords > 1 && (
        <div className="btnWrap mb-5 mt-4 text-center">
          <Link
            to={{ pathname: "/myArticle/rejected" }}
            state={"rejected"}
            className="button-border"
            onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            VIEW ALL REJECTED
          </Link>
        </div>
      )}

      {activeTab === "published" && totalRecords > 1 && (
        <div className="btnWrap mb-5 mt-4 text-center">
          <Link
            to={{ pathname: "/myArticle/published" }}
            state={"published"}
            className="button-border"
            onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            VIEW ALL PUBLISHED
          </Link>
        </div>
      )}
      <Modal
        show={availableGrants}
        size="sm"
        id="unicheck-results__modal"
        tabIndex={-1}
        dialogClassName="right authorModal "
      >
        <Modal.Header className="customHeader">
          <h5 className="modal-title"> Available Grants</h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={(e) => setavailableGrants(false)}
          />
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0">
          <div className="draftTable">
            <table className="w-100">
              <thead>
                <tr>
                  <th className="text-left">Grant Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {grantData &&
                  grantData.length > 0 &&
                  grantData?.map((data) => {
                    return (
                      <>
                        <tr className="empty">
                          <td colspan="2"></td>
                        </tr>
                        <tr>
                          <td>{data?.grantName ? data?.grantName : "--"}</td>
                          <td>
                            <a
                              href="javascript:void(0)"
                              className="readMore"
                              onClick={() => getGrantDetail(data?._id)}
                            >
                              {" "}
                              DETAILS
                            </a>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDetail}
        dialogClassName="modal-dialog-centered modal-lg authorModal"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">Grant Details</h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="grantDetails">
            <div className="row">
              <div className="col-md-12">
                <div className="infotext">
                  <b>Grant Name</b>
                  <span className="textGreen">{grantDetail?.grantName}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="infotext">
                  <b>Available For</b>
                  <span className="textGreen">{grantDetail?.availableFor}</span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="infotext">
                  <b>Grant Value</b>
                  <span className="textGreen">
                    INR {grantDetail?.grantValue}
                  </span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="infotext">
                  <b className="pb-2">&nbsp;</b>
                  Per Individual
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="infotext">
                  <b>Start Date</b>
                  <span className="textGreen">
                    <td> {getDateFormat(grantDetail?.createdAt)}</td>
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="infotext">
                  <b>
                    End Date
                    <div className="customTool">
                      {["bottom"].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Popover>
                              <Popover.Body>
                                <div className="popoverToolWrap">
                                  <p className="mb-3">
                                    The start and end dates represent the period
                                    for which the Grant is available with the
                                    platform. You can use the grant only at the
                                    time of making the final payment.
                                  </p>
                                  <p>
                                    Please note that grant maybe requested by
                                    multiple researchers at the same time.
                                    Therefore, we recommend that you make the
                                    balance payment at the earliest.
                                  </p>
                                  <p>
                                    Do note that the grant approval lapses with
                                    the lapse of your approved article should
                                    you not make the balance payment. You may be
                                    asked to reapply for the grant and it is
                                    possible the grant though made available to
                                    you now, is not available at a later date.
                                  </p>
                                  <div className="helpPopupText pb-0 pt-2">
                                    Need help? Write to us at
                                    <a href="mailto:contactus@vikramshilaedu.in">
                                      contactus@vikramshilaedu.in
                                    </a>
                                  </div>
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <img
                            className="pe-cursor ps-1"
                            src={active_info}
                            alt="no img"
                          />
                        </OverlayTrigger>
                      ))}
                    </div>
                  </b>
                  <span className="textGreen">
                    <td> {getDateFormat(grantDetail?.endDate)}</td>
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="infotext">
                  <b>Description</b>
                  <span className="textGreen">{grantDetail?.description}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="infotext">
                  <b>
                    Eligibility{" "}
                    
                  </b>
                  <span className="textGreen">{grantDetail?.eligibility}</span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="pb-4">
          {showButton === true && (
            <button
              class="button button-primary"
              onClick={() => [setShowDetail(false), setapplyGrant(true)]}
            >
              Apply For Grant
            </button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        show={applyGrant}
        dialogClassName="modal-dialog-centered modal-lg authorModal"
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header className="modal-header">
            <h5 className="modal-title">Grant Application</h5>
            <button
              type="button"
              className="btn-close"
              ariaLabel="Close"
              onClick={(e) => setapplyGrant(false)}
            />
          </Modal.Header>
          <Modal.Body className="pb-0">
            <div className="grantDetails">
              <div className="row">
                <div className="col-md-6">
                  <div className="infotext">
                    <b>Grant Name</b>
                    <span className="textGreen">{grantDetail?.grantName}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="infotext">
                    <b>Applicant Name</b>
                    <span className="textGreen">
                      {articleDetailData?.applicantName}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="infotext">
                    <b>Article </b>
                    <span className="textGreen">
                      {articleDetailData?.article}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="infotext">
                    <b>Proposal (word limit - 300)</b>
                    <textarea
                      className="fieldForm"
                      id="proposal"
                      name="proposal"
                      value={formData.proposal}
                      onChange={handleChangeProposal}
                    />
                    <small className="fieldError">
                      {formErrors.proposal && `${formErrors.proposal}`}
                    </small>
                  </div>
                </div>
              </div>
              
              {fundingStatus && (
                <div>
                  <div className="infotext">
                    <div className="knowMore">Funding Information</div>
                  </div>
                  <ul className="knowList">
                    <li>
                      <p>{fundingStatus}</p>
                    </li>
                  </ul>
                </div>
              )}
              
            </div>
          </Modal.Body>
          <Modal.Footer className="pb-3">
            <button class="button button-primary" type="submit">
              Submit
            </button>
            <div class="helpPopupText mt-4">
              Need help? Write to us at
              <a href="">contactus@vikramshilaedu.in</a>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={show} size="md" centered>
        <Confetti width={"450px"} height={"300px"} numberOfPieces={100} />
        <Modal.Header>
          <div class="modal-icon">{/* <img src={doneSvg} alt="#" /> */}</div>
          <Modal.Title className="modal-title">
            Thank you for applying
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0 pt-0">
          <div className="grantDetails submitArticle pl-2 pr-2">
            <div className="row">
              <div className="col-md-12">
                <p>
                  Your grant application is successfully submitted. Please check
                  your mailbox for further details.
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="button button-primary"
            onClick={() => [setShow(false), fetchArticleData(activeTab)]}
          >
            That's Great
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={received}
        onHide={""}
        dialogClassName="modal-dialog-centered modal-lg authorModal"
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">
            Grant Received <img src={grant} />
          </h5>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={(e) => setReceived(false)}
          />
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="grantDetails">
            <div className="row">
              <div className="col-md-6">
                <div className="infotext">
                  <b>Grant Name</b>
                  <span className="textBlue">{grantDetail?.grantName}</span>
                </div>
              </div>
              
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="infotext">
                  <b>Article </b>
                  <span className="textGreen">
                    {articleDetailData?.article}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="infotext">
                  <b>Description</b>
                  <span className="textGreen">{grantDetail?.description}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="infotext">
                  <b>Eligibility</b>
                  <span className="textGreen">{grantDetail?.eligibility}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="infotext">
                  <b className="knowMore">
                    Know More
                    <div className="customTool">
                      {["bottom"].map((placement) => (
                        <OverlayTrigger
                          key={placement}
                          placement={placement}
                          overlay={
                            <Popover>
                              <Popover.Body>
                                <div className="popoverToolWrap">
                                  <p className="mb-3">
                                    The start and end dates represent the period
                                    for which the Grant is available with the
                                    platform. You can use the Grant only at the
                                    time of making the final payment.
                                  </p>
                                  <p>
                                    Please note that the Grant maybe requested
                                    by multiple researchers at the same time.
                                    Therefore, we recommend that you make the
                                    balance payment at the earliest.
                                  </p>
                                  <p>
                                    Do note that the Grant approval lapses with
                                    the lapse of your approved article should
                                    you not make the balance payment. You may be
                                    asked to reapply for the Grant and it is
                                    possible the Grant though made available to
                                    you now, is not available at a later date.
                                  </p>
                                  <div className="helpPopupText pb-0 pt-2">
                                    Need help? Write to us at
                                    <a href="mailto:contactus@vikramshilaedu.in">
                                      contactus@vikramshilaedu.in
                                    </a>
                                  </div>
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <img
                            className="pe-cursor ps-1"
                            src={active_info}
                            alt="no img"
                          />
                        </OverlayTrigger>
                      ))}
                    </div>
                  </b>
                  <ul className="knowList">
                    <li>
                      <p>
                        <i>Grant Received On</i>
                        <small>{getDateFormat(grantDetail?.updatedAt)}</small>
                      </p>
                      <p>
                        <i>Valid Till</i>
                        <small>{getDateFormat(grantDetail?.endDate)}</small>
                      </p>
                      <p>
                        <i>Amount Received</i>
                        <small>INR {grantDetail?.grantValue}</small>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="pb-4">
          <button
            class="button button-primary"
            onClick={(e) => setReceived(false)}
          >
            CLOSE
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showReject}
        size="md"
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        className="md-x"
        centered
      >
        <Modal.Header>
          <Modal.Title>Grant Application Rejection Reason</Modal.Title>
          <button
            type="button"
            className="btn-close"
            ariaLabel="Close"
            onClick={() => setShowReject(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="reasonBg">{reason}</div>

          <span className="pt-3 d-block">
            Please revisit the Grant details for more clarity.
          </span>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <button
            className="button button-primary"
            onClick={() => setShowReject(false)}
          >
            Okay
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal"
        show={showWorkSaved.status}
        id="congratsMobile"
        tabIndex={-1}
        size="md"
        className="authorModal md-x"
      >
        <Modal.Header className="modal-header">
          <div class="modal-icon"> {/* <img src={doneSvg} alt="#" /> */}</div>
          <h2 class="modal-title pl-0 pr-0">PUBLISH ARTICLE</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() =>
              setShowWorkSaved({
                articleId: "",
                PID: "",
                status: "",
                finalPayment: "",
              })
            }
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <p className="mb-1">
            We are glad you have decided to publish your article.
          </p>
          <p>We will now take you to the final payment screen.</p>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          {showWorkSaved?.finalPayment ? (
            <button
              class="button button-primary"
              onClick={() => publishArticleAPICall()}
            >
              CONTINUE
            </button>
          ) : (
            <Link
              to={{
                pathname: `/dashboard/edit-article/${showWorkSaved.articleId}`,
              }}
            >
              <button class="button button-primary">CONTINUE</button>
            </Link>
          )}
        </Modal.Footer>
      </Modal>

      <ReasonModal
        showNotAccepted={showNotAccepted}
        setShowNotAccepted={setShowNotAccepted}
        reasonHandle={reasonHandle}
        reasonComments={reasonComments}
        peActiveTab={activeTab}
        _id={articleId}
        rejectionArticleId={rejectionArticleId}
        prReviewReport={prReviewReport}
        userType="Author"
      />

      <NeedHelpModal 
        showHelpModal={showHelpModal}
        setShowHelpModal={setShowHelpModal}
      />

      {amendmentData?.length >= 2 ? (
        <Modal
          size="lg"
          show={noMoreAmendment}
          id="congratsMobile"
          backdrop="static"
          tabIndex={-1}
          className="  authorModal authorModalRed"
          centered
        >
          <Modal.Header className="modal-header">
            <div className="modal-icon">
              {" "}
              <img src={alertSvg} alt="#" />
            </div>
            <h5 className="modal-title">Important Update for Your Research Article</h5>
            <button
              type="button"
              className="btn-close"
              ariaLabel="Close"
              onClick={() => setNoMoreAmendment(false)}
            ></button>
          </Modal.Header>
          <Modal.Body className="pt-0 pb-0">
            <div className="congratulationsPopup">
              
              <p>We have noticed that you have made amendments to your article on two occasions up to this point.</p>
              <p>In accordance with our publishing guidelines, we permit a maximum of two revisions.</p>
              <p>Please be aware that any further amendments to the current article will be considered a new version of the existing article. To initiate this update, please accept the button below, and we will send you a coupon worth {nationality === "withInIndia" ? "INR 5000" : "USD 250"}, applicable against the current publishing price of a new article.</p>
              <p>We acknowledge the importance of honing your research, and we are available to assist you throughout this endeavour. By selecting the update option, you ensure that your article remains a reflection of your most recent discoveries and insights.</p>
              <div className="text-center mb-4">
              <button className="button button-primary redBtn" onClick={() => {
                setNoMoreAmendment(false);
                setUpdateArticlePopup(true);
              }}>
                Yes, I Want To Update My Article
              </button>
              </div>
              
            </div>
          </Modal.Body>

          <Modal.Footer className="pb-0 pt-0">
            <div className="helpPopupText pb-2">
              In case of any queries, please reach us at{" "}
              <a href="mailto:contactus@vikramshilaedu.in">
                contactus@vikramshilaedu.in
              </a>
            </div>
          </Modal.Footer>
        </Modal>
      ) : (
        <RequestAmmended
          amendmentPopup={amendmentPopup}
          setamendmentPopup={setamendmentPopup}
          amendmentSharingPopup={amendmentSharingPopup}
          setamendmentSharingPopup={setamendmentSharingPopup}
          amendmentArticleId={amendmentArticleId}
          amendmentData={amendmentData}
          articleName={articleName}
          authorName={authorName}
          setAmendmentUpdateTrigger={setAmendmentUpdateTrigger}
        />
      )}

      {articleUpdateRequest && articleUpdateNewVersion ? (
        <UpdateArticleRequest
          showNewArticleRequest={showNewArticleRequest}
          setShowNewArticleRequest={setShowNewArticleRequest}
          amendmentArticleId={amendmentArticleId}
        />
      ) : articleUpdateRequest === true ? (
        <UpdateArticleRequest
          alreadyUpdatedPopup={alreadyUpdatedPopup}
          setAlreadyUpdatedPopup={setAlreadyUpdatedPopup}
          amendmentArticleId={amendmentArticleId}
        />
      ) : (
        <UpdateArticleRequest
          updateArticlePopup={updateArticlePopup}
          setUpdateArticlePopup={setUpdateArticlePopup}
          updateSharingPopup={updateSharingPopup}
          setUpdateSharingPopup={setUpdateSharingPopup}
          amendmentArticleId={amendmentArticleId}
          articleName={articleName}
          authorName={authorName}
          journalName={journalName}
          subjectName={subjectName}
          setUpdateTrigger={setUpdateTrigger}
        />
      )}
    </>
  );
};

export default ArticleListing;
