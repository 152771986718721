import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { Pagination } from "semantic-ui-react";
import ArticleListing from "../../../components/dashboard/ArticleListing";
import "./index.scss";
import downarrow from "../../../assets/img/icons/downarrow.svg";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Modal from "react-bootstrap/Modal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "../../../layout/dashboard";
import {
    getArticleDetails,
    getSearchUsers,
    getPEDetails,
    getPrinviteList,
    sendPrInvitation,
    appointPRRole,
} from "../../../store/apiCalls/dashboard";
import PEListing from "./PEListing";
// E:\React JS\ag-front\src\components\common\Select.js
import Select from "../../../components/common/Select";
import { getAllMasterData } from "../../../store/apiCalls/fetchmaster";
import { useSelector, useDispatch } from "react-redux";
import RecommendedArticles from "../dashboard/RecommendedArticles";

const PRListing = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state?.LoginReducer);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [updateRequestList, setUpdateRequestList] = useState(false);
    const [sentReminderPopup, setsentReminderPopup] = useState(false);
    const [sentReminderPopupId, setsentReminderPopupId] = useState('');
    const [deletePopup, setdeletePopup] = useState(false);
    const [deletePopupId, setdeletePopupId] = useState('');
    const [amendmentUpdateTrigger, setAmendmentUpdateTrigger] = useState(false);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [addPeerReviewerPop, setaddPeerReviewerPop] = useState(false);
    const { id } = jwt_decode(localStorage.usertoken);
    const [activeTab, setActiveTab] = useState("draft");
    const location = useLocation();
    const [latestPage, setLatestPage] = useState(1);
    const [latestArticlesTotalPages, setlatestArticlesTotalPages] = useState(1);
    const [peActiveTab, setPEActiveTab] = useState("draft");
    const [peData, setPEData] = useState([]);
    const [totalPEData, setTotalPEData] = useState([]);
    const [peerreviewlist, setpeerreviewlist] = useState([
        {
            name: "",
            email: "",
            university: "",
            subject: "",
        },
    ]);
    const [articleData, setArticleData] = useState([]);
    const [search, setSearch] = useState();
    const [searchResult, setSearchResult] = useState([]);
    const [Subjectmaster, setSubjectmaster] = useState([]);

    const [articleTotalData, setArticleTotalData] = useState([]); 
    const [page, setPage] = useState(1);
    const limit = 10;
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const articleId = location?.state?._id

    const [collapseActive, setIsActive] = useState(false);
    const expandClick = (event) => {
        setIsActive((current) => !current);
    }; 
    async function getMasterList() {
        const msterlst = await getAllMasterData();
    
        if (msterlst.status === 200) {
            const subjectLists = [];
            const subjectSpecialization = [];
            msterlst.data.subjectdata.forEach((element) => {
              const data = { key: element._id, value: element.subjectName };
              subjectLists.push(data);
              subjectSpecialization[element._id] = element.specializationId;
            });
      
            setSubjectmaster(subjectLists);
            // setSubjectmaster(msterlst.data.specializationdata);
        //   setspecializationList(msterlst.data.specializationdata);
        //   const journalMaster = [];
        //   const journalType = [];
        //   msterlst.data.jouranlData.forEach((element) => {
        //     const data = { key: element._id, value: element.journalType };
        //     journalType.push(data);
        //     journalMaster[element._id] = element.journalName;
        //   });
    
        //   setgernalMaster(journalMaster);
        //   setgernalType(journalType);
        //   setdesciplineMaster(msterlst.data.disciplineData);
        
      }
    }

    const fetchPRInvitation = async (tab) => {
        dispatch({ type: "SET_LOADER", payload: true });
        const params = {
            userId: id,
            page: page,
            limit,
        };
        const response = await getPrinviteList(headers, params);
        // console.warn(response)
        if (response?.status === 200) {
            dispatch({ type: "SET_LOADER", payload: false });
            setSearchResult(response?.data?.data);
            // setTotalPEData(response?.data?.data); 
        } else {
            dispatch({ type: "SET_LOADER", payload: true });
            return toast.error("Please try again");
        }
    };
    const fetchPublishingEditor = async (tab) => {
        dispatch({ type: "SET_LOADER", payload: true });
        const params = {
            userId: id,
            page: page,
            limit,
            peStatus: tab,
        };
        const response = await getPEDetails(headers, params);
        if (response?.status === 200) {
            dispatch({ type: "SET_LOADER", payload: false });
            setPEData(response?.data?.data?.data);
            setTotalPEData(response?.data?.data);
        } else {
            dispatch({ type: "SET_LOADER", payload: true });
            return toast.error("Please try again");
        }
    };

    const getPEType = (type) => {
        fetchPublishingEditor(type);
        setPEActiveTab(type);
    };
    /*serach by name  */

    const searchByName = async (search) => {
        const activate = true;
        let data = { page, limit, search, activate };
        const response = await getSearchUsers(headers, data);
        setSearchResult(response?.data?.data?.data);
    };

    const fetchArticleData = async (tab) => {
        dispatch({ type: "SET_LOADER", payload: true });
        const params = {
            userId: id,
            status: tab,
            page: page,
            limit: limit,
        };
        const response = await getArticleDetails(headers, params);
        if (response?.status === 200) {
            dispatch({ type: "SET_LOADER", payload: false });
            setArticleData(response?.data?.data?.article);
            setArticleTotalData(response?.data?.data);
        } else {
            dispatch({ type: "SET_LOADER", payload: false });
            return toast.error("Please try again");
        }
    };

    const getArticleType = (type) => {
        fetchArticleData(type); 
        setActiveTab(type);
    };
    async function sentReminder(dataid) {
        // const articleId = window.location.pathname.split("/")[5];
        const data = {
            status: "reminder",
            userId: id,
            id: dataid,
        };
        const response = await sendPrInvitation(headers, data);
        // console.log(response);
        if (response?.status === 201) {
            toast.success("Successfully Sent!!"); 
        } else {
            toast.error("Please try again!!"); 
        }
    }
    async function sentPrInvitation() {
        // const articleId = window.location.pathname.split("/")[5];
        const data = {
            peerreviewlist: peerreviewlist,
            userId: id,
        };
        const response = await sendPrInvitation(headers, data);
        // console.log(response);
        if (response?.status === 201 || response?.status === 200) {
            setaddPeerReviewerPop(false);
        } else {
            toast.error("Please try again!!"); 
        }
    }
    const invitePEhandleUpdate = (index, updatedFields) => {
        setpeerreviewlist(prevList => {
            const newList = [...prevList];
            newList[index] = { ...newList[index], ...updatedFields };
            return newList;
        });
    };
      // Handle adding a new peer review
      const handleAdd = () => {
        setpeerreviewlist(prevList => [
            ...prevList,
            { name: "", email: "", university: "", subject: "" }
        ]);
    };

    // Handle removing a peer review
    const handleRemove = (index) => {
        setpeerreviewlist(prevList => {
            const newList = [...prevList]; 
            newList.splice(index, 1);
            return newList;
        });
    };
    // console.log(peerreviewlist)

    useEffect(() => {
        fetchPRInvitation()
        getMasterList()
    },[]);
    useEffect(() => {
        if (updateTrigger) {
            fetchArticleData("published");
        } else if (amendmentUpdateTrigger) {
            fetchArticleData("published")
        } else {
            fetchArticleData("draft");
        }
        localStorage.setItem("getPEType", "");
        fetchPublishingEditor("draft");
        setIsActive(true);
    }, [page, updateTrigger, amendmentUpdateTrigger, updateRequestList]);
    const profileAs = localStorage.getItem("dropdownToggleVal");

    return (
        <>
            <DashboardLayout>
                <>
                    <div className="d-flex justify-content-between">
                        <p className="draftTitle">
                            Select Peer Reviewer
                            <span>
                                <b>Please type the name of the desired Peer Reviewer in the search bar below to assign the article to them.</b>
                            </span>
                        </p>
                        <span onClick={() => setaddPeerReviewerPop(true)} className="addpeerreviews-btn cursor-pointer">Add Peer Reviewers Here <span className="icon">+</span></span>
                    </div>
                    <div>
                        <input placeholder="Search by Name and Email Id"
                            name="search"
                            className="searchpr"
                            style={{ minWidth: "590px", minHeight: "50px" }}
                            value={search}
                            onChange={(event) => [
                                setSearch(event.target.value),
                                searchByName(event.target.value),
                            ]} />
                    </div>
                    <div className="mt-4">
                        <table className="table tableadmin">
                            <thead>
                                <tr>
                                    <th>User</th>
                                    <th>Status</th>
                                    <th>Contact</th>
                                    {/* <th>Nationality</th> */}
                                    <th>Registered</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                               {searchResult?.map((list) => (
                                    <>

                                        <tr key={list?._id}>
                                            <td><span className="text-link">{list?.fullName}</span></td>
                                            <td>{list?.status}</td>
                                            <td className="tablewoutNum">
                                            <span >{list?.email}</span>
                                                
                                            </td>
                                            {/* <td>{list?.nationality === "withInIndia" ? "Within India" : "Outside India"}</td> */}
                                            <td>{new Date(list?.createdAt).toLocaleDateString('en-GB', {
                                                day: 'numeric',
                                                month: 'short',  // 'short' gives abbreviated month names like "Sep"
                                                year: 'numeric'
                                            })}</td>
                                            {/* sentReminder(list?._id) */}
                                            <td><span onClick={(e)=> {setsentReminderPopup(true),setsentReminderPopupId(list?._id)}} class="text-link">Send Reminder</span></td>
                                            <td><span  onClick={(e)=> {setdeletePopup(true),setdeletePopupId(list?._id)}} class="text-link">Delete</span></td>
                                        </tr>


                                    </>))}
                                    {/* <tr>
                                        <td><span class="text-link">Deepansh sagar</span></td>
                                        <td>Invitation Sent</td>
                                        <td><span>deepansh.techsaga@gmail.com</span><br /><span>8457512568</span></td>
                                        <td>Within India</td>
                                        <td>22 Apr 2024</td>
                                        <td><span class="text-link">Send Reminder</span></td>
                                        <td><span class="text-link">Delete</span></td>
                                    </tr> */}

                            </tbody>
                        </table>
                        <Pagination
                                                        boundaryRange={1}
                                                        firstItem={null}
                                                        lastItem={null}
                                                        siblingRange={1}
                                                        activePage={latestPage}
                                                        prevItem={{ content: <span><b class="icon-arrow-left pe-2" />Previous</span> }}
                                                        nextItem={{ content: <span>Next<b class="icon-arrow-right ps-2" /></span> }}
                                                        onPageChange={(event, data) => setLatestPage(data.activePage)}
                                                        totalPages={latestArticlesTotalPages}
                                                    />
                    </div>
                </>
                <Modal
                    show={sentReminderPopup}
                    onHide={handleClose}
                    dialogClassName="modal-dialog-centered  authorModal  modal-w-500"
                >
                    <Modal.Header className="modal-header">
                        <h5 className="modal-title">Send Reminder</h5>
                        <button
                            type="button"
                            className="btn-close"
                            ariaLabel="Close"
                            onClick={(e) => setsentReminderPopup(false)}
                        />
                    </Modal.Header>
                    <Modal.Body className="pb-3 pt-0">
                    You are going to remind the newly added Peer Reviewers to complete their registration on the aakashganga Platform. 
                    </Modal.Body>
                    <Modal.Footer className="pb-4">
                        <button
                            class="button button-primary"
                            type="submit"
                            onClick={(e) => {setsentReminderPopup(false), sentReminder(sentReminderPopupId)} }
                        >
                           Go ahead
                        </button>
                        <button
                            class="button button-primary"
                            type="submit"
                            onClick={(e) => setsentReminderPopup(false)}
                        >
                           Dashboard
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={deletePopup}
                    onHide={handleClose}
                    dialogClassName="modal-dialog-centered modal-w-405 authorModal"
                >
                    <Modal.Header className="modal-header">
                        <h5 className="modal-title">Delete Peer reviewer</h5>
                        <button
                            type="button"
                            className="btn-close"
                            ariaLabel="Close"
                            onClick={(e) => setdeletePopup(false)}
                        />
                    </Modal.Header>
                    <Modal.Body className="pb-3 pt-0">
                    Are you sure you want to delete the peer reviewer. This action cannot be undone.
                    </Modal.Body>
                    <Modal.Footer className="pb-4">
                        <button
                            class="button button-primary"
                            type="submit"
                            onClick={(e) => setdeletePopup(false)}
                        >
                           Yes, I'm sure
                        </button>
                        <button
                            class="button button-primary"
                            type="submit"
                            onClick={(e) => setdeletePopup(false)}
                        >
                            No, Just checking
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={show}
                    onHide={handleClose}
                    dialogClassName="modal-dialog-centered modal-lg authorModal"
                >
                    <Modal.Header className="modal-header">
                        <h5 className="modal-title">Grant Details</h5>
                        <button
                            type="button"
                            className="btn-close"
                            ariaLabel="Close"
                            onClick={(e) => handleOTPClose(e)}
                        />
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div className="grantDetails">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="infotext">
                                        <b>Grant Name</b>
                                        <span className="textGreen">
                                            Vikramshila Grant for Authors
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="infotext">
                                        <b>Available for</b>
                                        <span className="textGreen">
                                            Vikramshila Grant for Authors
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="infotext">
                                        <b>URL of Grant available for </b>
                                        <span className="textBlue">
                                            Urban climate change management and society
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="infotext">
                                        <b>Description</b>
                                        <span className="textGray">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                            sed do eiusmod tempor incididunt ut labore et dolore magna
                                            aliqua. Ut enim ad minim veniam, quis labore et dolore
                                            magna aliqua labore et dolore magna...
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="infotext">
                                        <b>Eligibility</b>
                                        <ul className="listText">
                                            <li>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                            </li>
                                            <li>
                                                Sed do eiusmod tempor incididunt ut labore et dolore
                                                magna aliqua
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="infotext">
                                        <b>Know More</b>
                                        <ul className="knowList">
                                            <li>
                                                <p>
                                                    <i>Grant Received On</i>
                                                    <small>12 May 2023</small>
                                                </p>
                                                <p>
                                                    <i>Valid Till</i>
                                                    <small>25 Aug 2023</small>
                                                </p>
                                            </li>

                                            <li>
                                                <p>
                                                    <i>Amount Received</i>
                                                    <small>INR 7000</small>
                                                </p>
                                                <p>
                                                    <i>Amount Remaining</i>
                                                    <small>INR 6500</small>
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    <i>Transaction ID</i>
                                                    <small>826475844855</small>
                                                </p>
                                                <p>
                                                    <i>Invoice</i>
                                                    <div className="downloadBOx">
                                                        <a href="" download></a>
                                                        <strong>Download</strong>
                                                    </div>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="pb-4">
                        <button
                            class="button button-primary"
                            type="submit"
                            onClick={handleClose}
                        >
                            CLOSE
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={addPeerReviewerPop}
                    onHide={handleClose}
                    dialogClassName="modal-dialog-centered modal-lg authorModal"
                >
                    <Modal.Header className="modal-header">
                        <h5 className="modal-title">Add Peer Reviewers</h5>
                        <button
                            type="button"
                            className="btn-close"
                            ariaLabel="Close"
                            onClick={(e) => setaddPeerReviewerPop(false)}
                        />
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div className="peerreview">
                            {peerreviewlist.map((val, ind) => ( 
                                <div className="" key={ind}>
                                    <div className="d-flex justify-content-between px-3">
                                        <p className="theme-orange-color text-start fs-6 fw-bold mb-3">Peer Reviewer {ind+1}</p>
                                        <p onClick={(e) => handleRemove(ind)} className="theme-blue-color text-start pe-1 fs-6 fw-bold mb-3">Remove</p>
                                    </div>
                                    <div className="row mb-3 px-3">
                                        <div className="col-6">
                                            <label className="labelForm text-black p-0">Name</label>
                                            <input className="form-control" value={val.name} type="text"  onChange={(e) => invitePEhandleUpdate(ind, { name: e.target.value })} placeholder="Full Name" />
                                        </div>
                                        <div className="col-6">
                                            <label className="labelForm text-black p-0">Email ID</label>
                                            <input className="form-control" value={val.email} onChange={(e) => invitePEhandleUpdate(ind, { email: e.target.value })} type="text" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="row mb-4 px-3">
                                        <div className="col-6">
                                            <label className="labelForm text-black p-0">Affiliated Institution/University</label>
                                            <input className="form-control" value={val.university} onChange={(e) => invitePEhandleUpdate(ind, { university: e.target.value })} type="text" placeholder="Affiliated Institution/University" />
                                        </div>
                                        <div className="col-6">
                                            <label className="labelForm text-black p-0" >Subject</label>
                                            <input className="form-control" value={val.subject} onChange={(e) => invitePEhandleUpdate(ind, { subject: e.target.value })} type="text" placeholder="Subject" />
                                            <div className="selectBox">
                                 {/* <Select
                                    // selectedValue={
                                    //   reviewerDetails?.specialization[index].val
                                    // }
                                    options={Subjectmaster.specializationList?.map(
                                      (val) => val?.specialization
                                    )}
                                    optionValues={Subjectmaster.specializationList?.map(
                                      (val) => val?._id
                                    )}
                                    // name={`specialization${index}`}
                                    placeholder="Search and Select"
                                    // handleChange={(e) =>
                                    //   handleSelectChangesReviewer(
                                    //     e,
                                    //     index,
                                    //     "specialization"
                                    //   )
                                    // }
                                    required={true}
                                    mandatory={false}
                                    isSearchable={true}
                                    
                                  /> */}
                                  
                                </div>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            ))}
                            <a className="d-block text-start fw-bold" onClick={(e) =>{handleAdd()}}>+ Add More Peer Reviewer</a>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="pb-4">
                        <button
                            class="button button-primary"
                            type="submit"
                            onClick={sentPrInvitation}
                        >
                           Submit
                        </button>
                    </Modal.Footer>
                </Modal>
            </DashboardLayout>

        </>
    );
};
export default PRListing;
