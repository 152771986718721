
import React from 'react';
import fullscreen from "../../assets/img/icons/fullscreen.svg";
const PublishedDate = ({ pagename, details, onChange }) => {
    const handleClick = (e) => {
        e.preventDefault();
        onChange();
      };
    return (
        <div className="publishedBox stylechange">
            <div className="innerMergin justify-content-between">
            <div className='d-flex'><span>Published: &nbsp;</span> <div>{pagename==='previewArticle' || details?.status !== "published" ? <>--</> : details?.status === "published" ? details.publishedDate : null}</div>
           
            {details?.doiUrl?(<>
                <a className='ms-5' href={details?.doiUrl} >{details?.doiUrl}</a>
            </>):null}
            
            </div>
            {pagename == "viewPublishedArticle"?(<>
            <div className='viewcommentline'>
                <a className='me-5' href='' onClick={handleClick}>Read Full Screen <img className='ps-2' src={fullscreen} alt="" /></a>
             <a href="#viewScrollReviews" >View comments on this article</a>
            </div>
            </>):null}
            </div>
        </div>
   
    );
};
export default PublishedDate