import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import doneSvg from "../../../assets/img/icons/done02.svg";
/*eslint-disable*/
import { Pagination } from "semantic-ui-react";
import notyet from "../../../assets/img/icons/notarticle.svg";
import DashboardLayout from "../../../layout/dashboard";
import Dropdown from "react-bootstrap/Dropdown";
import jwt_decode from "jwt-decode";
import Confetti from "react-confetti";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  peRequest,
  peAccepted,
  peReview,
  seAccepted,
  pePeerReview,
  peApproved,
  peRevision,
  peRejected,
} from "../../../constants";
import { get15DaysAheadDate, getDateFormat } from "../../../utils";
import backArrow from "../../../assets/img/icons/blueArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptRejectArticle,
  getPEDetails,
  getSocietyPEDetails,
  notAcceptedArticleReason,
  reviewArticle,
} from "../../../store/apiCalls/dashboard";
import { toast } from "react-toastify";
import RejectModal from "../prdashboard/RejectModal";
import ReasonModal from "../prdashboard/ReasonModal";
import ArticleDetailsModal from "../prdashboard/ArticleDetailsModal";
import ConfirmationalModal from "../prdashboard/ConfirmationModal";

const PublishingEditor = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const masterData = useSelector((state) => state.MasterDataReducer.MasterData);
  let status = location?.state;
  const navigate = useNavigate();
  const limit = 10;
  const [page, setPage] = useState(1);
  const { id } = jwt_decode(localStorage.usertoken);
  const [showReject, setShowReject] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [markCompletePopup, setmarkCompletePopup] = useState(false);
  const [articleRessign, setarticleRessign] = useState(false);
  const [deletePopup, setdeletePopup] = useState(false);
  const [markCompletePopupId, setmarkCompletePopupId] = useState('');
  const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showNotAccepted, setShowNotAccepted] = useState(false);
  const [reasonHandle, setReasonHandle] = useState("");
  const [reasonComments, setReasonComments] = useState("");
  const [rejectionArticleId, setRejectionArticleId] = useState("");
  const [articleId, setArticleId] = useState("");
  const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
  const [showArticleDetails, setShowArticleDetails] = useState(false);
  const [updateRequestList, setUpdateRequestList] = useState(false);
  const [peData, setPEData] = useState([]);
  const [totalPEData, setTotalPEData] = useState([]);
  const [articleData, setArticleData] = useState([]);
  const [currentData, setCurrentData] = useState([]); 
  const [userDetail, setUserDetail] = useState([]);
  const [clicked, setClicked] = useState("");
  const [title, setTitle] = useState(-1);
  const [articleType, setArticleType] = useState(-1);
  const [dueBy, setDueBy] = useState(-1);
  const [received, setReceived] = useState(-1);
  const [requested, setRequested] = useState(-1);
  const [sent, setSent] = useState(-1);
  const [approved, setApproved] = useState(-1);
  const [rejected, setRejected] = useState(-1);
  let totalPages = Math.ceil(totalPEData?.numberRecordBaseOnFilter / limit);

  const fetchPublishingEditor = async (tab) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const params = () => {
      const data = {
        userId: id,
        page,
        limit,
        peStatus: tab,
      };
      if (clicked === "TITLE" && title) {
        data.title = title;
      }
      if (clicked === "ARTICLE TYPE" && articleType) {
        data.articleType = articleType;
      }
      if (clicked === "DUE BY" && dueBy) {
        data.dueBy = dueBy;
      }
      if (
        (clicked === "RECEIVED" || clicked === "REVIEW RECEIVED") &&
        received
      ) {
        data.received = received;
      }
      if (clicked === "REQUESTED" && requested) {
        data.requested = requested;
      }
      if (clicked === "SENT" && sent) {
        data.sent = sent;
      }
      if (clicked === "APPROVED" && approved) {
        data.approved = approved;
      }
      if (clicked === "REJECTED" && rejected) {
        data.rejected = rejected;
      }
      return data;
    };
    const response = await getSocietyPEDetails(headers, params());
    
   
    if (response?.status === 200) {
      dispatch({ type: "SET_LOADER", payload: false });
      setPEData(response?.data?.data?.data);
      setTotalPEData(response?.data?.data);
    } else {
      dispatch({ type: "SET_LOADER", payload: true });
      return toast.error("Please try again");
    }
  };

  const handleRedirect = (e, path = "/dashboard") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };

  const markCompleteHandle = async (ID) => {

    // const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    // const params = {
    //   id: _id,
    //   peStatus: status,
    //   peId: id,
    // };
    // const response = await acceptRejectArticle(headers, params);
    // if (response?.status === 200) {
    //   setShowAcceptConfirmation(false);
    //   setShowAccept(true);
    //   fetchPublishingEditor("draft");
    // } else {
    //   return toast.error("Please try again!!");
    // }
  };
  const handleAcceptRejectArticle = async (_id, status) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: _id,
      peStatus: status,
      peId: id,
    };
    const response = await acceptRejectArticle(headers, params);
    if (response?.status === 200) {
      setShowAcceptConfirmation(false);
      setShowAccept(true);
      fetchPublishingEditor("draft");
    } else {
      return toast.error("Please try again!!");
    }
  };

  const handleAcceptedArchiveArticle = async (_id, status, archiveStatus) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: _id,
      peStatus: status,
      peId: id,
      previousUndoState: archiveStatus,
    };
    const response = await acceptRejectArticle(headers, params);
    if (response?.status === 200) {
      fetchPublishingEditor("approved");
    } else {
      return toast.error("Please try again!!");
    }
  };

  const handleRejectedArchiveArticle = async (_id, status, archiveStatus) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      id: _id,
      peStatus: status,
      peId: id,
      previousUndoState: archiveStatus,
    };
    const response = await acceptRejectArticle(headers, params);
    if (response?.status === 200) {
      fetchPublishingEditor("rejected");
    } else {
      return toast.error("Please try again!!");
    }
  };

  const sortingData = (header) => {
    if (header === "RECEIVED" || header === "REVIEW RECEIVED") {
      if (received == -1) {
        setReceived(1);
      } else {
        setReceived(-1);
      }
    } else if (header === "ARTICLE TYPE") {
      if (articleType == -1) {
        setArticleType(1);
      } else {
        setArticleType(-1);
      }
    } else if (header === "TITLE") {
      if (title == -1) {
        setTitle(1);
      } else {
        setTitle(-1);
      }
    } else if (header === "REQUESTED") {
      if (requested === -1) {
        setRequested(1);
      } else {
        setRequested(-1);
      }
    } else if (header === "DUE BY") {
      if (dueBy === -1) {
        setDueBy(1);
      } else {
        setDueBy(-1);
      }
    } else if (header === "SENT") {
      if (sent === -1) {
        setSent(1);
      } else {
        setSent(-1);
      }
    } else if (header === "APPROVED") {
      if (approved === -1) {
        setApproved(1);
      } else {
        setApproved(-1);
      }
    } else if (header === "REJECTED") {
      if (rejected === -1) {
        setRejected(1);
      } else {
        setRejected(-1);
      }
    }
  };

  const handleNotAcceptedReason = async (articleId) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      userId: id,
      articleId,
      status: "pe",
    };
    const response = await notAcceptedArticleReason(headers, params);
    if (response?.status === 200) {
      setShowNotAccepted(true);
      setReasonHandle(response?.data?.data?.[0]?.reason);
      setReasonComments(response?.data?.data?.[0]?.additionalComment);
    } else {
      return toast.error("Please try again!!");
    }
  };

  const getReviewArticle = async (_id) => {
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
    const params = {
      articleId: _id,
    };
    const response = await reviewArticle(headers, params);
    if (response?.status === 200) {
      setArticleData(response?.data?.data);
      setCurrentData(response?.data?.currentStatus);
      setUserDetail(response?.data?.userDetail);
      setShowArticleDetails(true);
    } else {
      return toast.error("Please try again!!");
    }
  };

  const getUserDetails = (user) => {
    return (
      user?.name +
      ", " +
      user?.department +
      ", " +
      user?.city +
      ", " +
      user?.state
    );
  };

  const handlePageChange = (data) => {
    return (
      setPage(data.activePage),
      window.scrollTo({ top: 0, behavior: "smooth" })
    );
  };
  const typeOfArticle = (data) => {
    const article = masterData?.typeOfArticledata?.find(article => article?._id === data);
    return article ? article?.articleName : 'ID not found';
  };

  useEffect(() => {
    fetchPublishingEditor(status);
  }, [
    page,
    status,
    title,
    articleType,
    received,
    requested,
    dueBy,
    sent,
    approved,
    rejected,
    updateRequestList,
  ]);

  useEffect(() => {
    setPage(1);
  }, [status])

  return (
    <>
      <DashboardLayout>
        <p className="draftTitle">
          PR Appointment History
          <span>
            {status === "draft"
              ? "Please click on the article title to view the full article for review."
              : null}
          </span>
        </p>
        <div className="draftTable">
          <table className="w-100">
            {peData && peData.length > 0 && (
              <thead>
                <tr>
                  {status === "draft"
                    ? peRequest.map((requestHeader) => (
                      <th
                        className={
                          requestHeader === "ACTIONS"
                            ? "sortingWrap pe-request-th"
                            : "sortingWrap"
                        }
                        onClick={() => {
                          setClicked(requestHeader);
                          sortingData(requestHeader);
                        }}
                      >
                        <span>
                          {requestHeader}
                          {requestHeader === "ACTIONS" ? null : (
                            <b className="sort" />
                          )}
                        </span>
                      </th>
                    ))
                    : status === "accepted"
                      ? seAccepted.map((acceptedHeader) => (
                        <th
                          className={
                            acceptedHeader === "ACTIONS"
                              ? "sortingWrap pe-accepted-th"
                              : "sortingWrap"
                          }
                          onClick={() => {
                            setClicked(acceptedHeader);
                            sortingData(acceptedHeader);
                          }}
                        >
                          <span>
                            {acceptedHeader === "ACTIONS" ? null : acceptedHeader}
                            {acceptedHeader === "ACTIONS" ? null : (
                              <b className="sort" />
                            )}
                          </span>
                        </th>
                      ))
                      : status === "sendForReview"
                        ? peReview.map((reviewHeader) => (
                          <th
                            className={
                              reviewHeader === "STATUS"
                                ? "sortingWrap pe-review-th"
                                : "sortingWrap"
                            }
                            onClick={() => {
                              setClicked(reviewHeader);
                              sortingData(reviewHeader);
                            }}
                          >
                            <span>
                              {reviewHeader}
                              <b className="sort" />
                            </span>
                          </th>
                        ))
                        : status === "peerReview"
                          ? pePeerReview.map((peerHeader) => (
                            <th
                              className={
                                peerHeader === "ACTIONS"
                                  ? "sortingWrap pe-peerReview-th"
                                  : "sortingWrap"
                              }
                              onClick={() => {
                                setClicked(peerHeader);
                                sortingData(peerHeader);
                              }}
                            >
                              <span>
                                {peerHeader}
                                {peerHeader === "ACTIONS" ? null : (
                                  <b className="sort" />
                                )}
                              </span>
                            </th>
                          ))
                          : status === "approved"
                            ? peApproved.map((approvedHeader) => (
                              <th
                                className={
                                  approvedHeader === "ACTIONS"
                                    ? "sortingWrap pe-approved-th"
                                    : "sortingWrap"
                                }
                                onClick={() => {
                                  setClicked(approvedHeader);
                                  sortingData(approvedHeader);
                                }}
                              >
                                <span>
                                  {approvedHeader}
                                  {approvedHeader === "ACTIONS" ? null : (
                                    <b className="sort" />
                                  )}
                                </span>
                              </th>
                            ))
                            : status === "sentForRevision"
                              ? peRevision.map((revisionHeader) => (
                                <th
                                  className={
                                    revisionHeader === "ACTIONS"
                                      ? "sortingWrap pe-revision-th"
                                      : "sortingWrap"
                                  }
                                  onClick={() => {
                                    setClicked(revisionHeader);
                                    sortingData(revisionHeader);
                                  }}
                                >
                                  <span>
                                    {revisionHeader}
                                    {revisionHeader === "ACTIONS" ? null : (
                                      <b className="sort" />
                                    )}
                                  </span>
                                </th>
                              ))
                              : (status === "rejected" || status === "notAccepted")
                                ? peRejected.map((rejectedHeader) => (
                                  <th
                                    className={
                                      rejectedHeader === "ACTIONS"
                                        ? "sortingWrap pe-rejected-th"
                                        : "sortingWrap"
                                    }
                                    onClick={() => {
                                      setClicked(rejectedHeader);
                                      sortingData(rejectedHeader);
                                    }}
                                  >
                                    <span>
                                      {rejectedHeader}
                                      {rejectedHeader === "ACTIONS" ? null : (
                                        <b className="sort" />
                                      )}
                                    </span>
                                  </th>
                                ))
                                : null}
                </tr>
              </thead>
            )}
            <tbody>
              {peData && peData.length > 0 && (
                <>
                  {peData.map((item, ind) => (
                    <>
                      <tr className="empty"> 
                        <td colspan="5"></td>
                      </tr>
                      <tr>
                        <td class="firstDataBoxTd">
                          <div class="firstDataBox">
                            <span class="t-type mr-0">Assigned to</span>
                            <div class="draftArticleWrap">
                              <div class="authorName mb-3"><b>{item?.prData?.[0]?.title} {item?.prData?.[0]?.fullName}</b></div>
                              {/* <div class="authorAff">test, tesing, New Delhi, Delhi</div> */}
                              <h2><Link 
                              to={`/dashboard/article/${item?.article[0]?._id}/review-article`}
                              state={{
                                _id: item?.articleId,
                                particularId: item?._id,
                                createdAt: item?.createdAt,
                                updatedAt: item?.updatedAt,
                                peData: item?.peData,
                                typeOfReview:
                                item?.article?.[0]?.reviewType?.typeOfReview,
                                // status: peActiveTab,
                                // articleStatus: "First Revision",
                                sendForRevision:
                                item?.article?.[0]?.sendForRevision,
                              }}
                              
                              >{item?.article[0]?.title}</Link></h2>
                            </div>

                          </div>
                        </td>
                        <td>{typeOfArticle(item?.article[0]?.submissionDetail?.articleType)}</td>
                        <td>5 Oct 2024</td>
                        <td>30 Aug 2024</td>
                        <td>20</td>
                        <td class="pe-accepted-td">
                          <div class="vertical-footAction">
                            <div className="customDrop sideDrop dw-auto">
                              <Dropdown>
                                <Dropdown.Toggle className="button button-primary w-auto" variant="success">
                                  Action
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={(e) => {setmarkCompletePopup(true),setmarkCompletePopupId(item?._id) }}
                                    className="dropdown-item m-0"
                                  >
                                    Mark Complete 
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    as={Link}
                                    // to="/dashboard/society-editor/pr-listing"
                                    onClick={(e) => {setarticleRessign(true),setmarkCompletePopupId(item?._id) }}
                                    className="dropdown-item m-0"
                                  >
                                    Article Reassign
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    as={Link}
                                    // to="/dashboard/society-editor/pr-listing"
                                    onClick={(e) => {setdeletePopup(true),setmarkCompletePopupId(item?._id) }}
                                    className="dropdown-item m-0"
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>

                            </div>
                            {/* <div><a class="button button-primary" href="#">Reassign</a></div> */}
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                </>
              )}
              <tr className="empty">
                <td colspan="5"></td>
              </tr>
              <tr>
                <td class="firstDataBoxTd">
                  <div class="firstDataBox">
                    <span class="t-type mr-0">Assigned to</span>
                    <div class="draftArticleWrap">
                      <div class="authorName mb-3"><b>Mr. Vivek mehra</b></div>
                      {/* <div class="authorAff">test, tesing, New Delhi, Delhi</div> */}
                      <h2><a href="/dashboard/article/66c6debeb4f1e0d1210b079b/review-article">The Importance of Mental Health Awareness in the Workplace</a></h2>
                    </div>

                  </div>
                </td>
                <td>Case Reports</td>
                <td>5 Oct 2024</td>
                <td>30 Aug 2024</td>
                <td>20</td>
                <td class="pe-accepted-td">
                  <div class="vertical-footAction">
                    <div className="customDrop sideDrop dw-auto">
                      <Dropdown>
                        <Dropdown.Toggle className="button button-primary w-auto" variant="success">
                          Action
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            // onClick={handleAutomaticAppoint}
                            className="dropdown-item m-0"
                          >
                            Mark Complete
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Link}
                            // to="/dashboard/society-editor/pr-listing"

                            className="dropdown-item m-0"
                          >
                            Article Reassign
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Link}
                            // to="/dashboard/society-editor/pr-listing"

                            className="dropdown-item m-0"
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                    </div>
                    {/* <div><a class="button button-primary" href="#">Reassign</a></div> */}
                  </div>
                </td>
              </tr>


            </tbody>
          </table>
        </div>

        <div className="backFooterBox">
          <div className="backBtnWrap">
            <Link
              to="#"
              onClick={(e) => handleRedirect(e, "/dashboard/publishing-editor")}
            >
              <span>Back</span>
              <img src={backArrow} alt="#" />
            </Link>
          </div>
          {totalPages > 1 && (
            <div className="paginationWrap">
              <Pagination
                boundaryRange={1}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                activePage={page}
                prevItem={{ content: <b class="icon-arrow-left" /> }}
                nextItem={{ content: <b class="icon-arrow-right" /> }}
                onPageChange={(event, data) => handlePageChange(data)}
                totalPages={totalPages}
              />
            </div>
          )}
        </div>
        {/* <div
          className="pullUp"
          onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <b className="icon-arrow-right" />
        </div> */}
      </DashboardLayout>
      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={deletePopup}
        // id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      >
        <Modal.Header className="modal-header  pb-0">
          <h2 class="modal-title">Delete?</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => {setdeletePopup(false)}}
          ></button>
        </Modal.Header>

        

        <Modal.Footer className="modal-footer  pb-4">
          <span
            className="btn-link-active bold mr-3"
            // onClick={() => navigate("/dashboard/publishing-editor")}
            // onClick={() => {setmarkCompletePopup(false)}}
            onClick={() => {setdeletePopup(false)}}
          >
             No, Go back
          </span>
          <Link
            class="button button-primary"
            // onClick={() => handleAcceptRejectArticle(articleId, "accepted")}
            // onClick={() => {setmarkCompletePopup(false), markCompleteHandle(markCompletePopupId)}}
            onClick={() => {setdeletePopup(false)}}
            
                                 
          >
          Yes
          </Link>
        </Modal.Footer>
      </Modal>
      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={articleRessign}
        // id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      >
        <Modal.Header className="modal-header">
          <h2 class="modal-title">Article Reassign</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => {setarticleRessign(false)}}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="aaforAss text-center px-1">
            <p className="">
            You are going to reassign this article to another Peer Reviewer as the current reviewer has not completed their work in the given timeline. 
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="btn-link-active bold mr-3"
            // onClick={() => navigate("/dashboard/publishing-editor")}
            // onClick={() => {setmarkCompletePopup(false)}}
            onClick={() => {setarticleRessign(false)}}
          >
               Go back
          </span>
          <Link
            class="button button-primary"
            // onClick={() => handleAcceptRejectArticle(articleId, "accepted")}
            // onClick={() => {setmarkCompletePopup(false), markCompleteHandle(markCompletePopupId)}}
            onClick={() => {setarticleRessign(false)}}
            
                                  to="/dashboard/society-editor/pr-listing"
                                  state={{
                                    _id: markCompletePopupId,
                                  }}
          >
          Yes
          </Link>
        </Modal.Footer>
      </Modal>
      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={markCompletePopup}
        // id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      >
        <Modal.Header className="modal-header">
          <h2 class="modal-title">Mark Complete</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => {setmarkCompletePopup(false)}}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="aaforAss text-center px-1">
            <p className="">
            You are confirming that the Peer Review process has been completed by the assigned reserach scholar.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="btn-link-active bold mr-3"
            // onClick={() => navigate("/dashboard/publishing-editor")}
            // onClick={() => {setmarkCompletePopup(false)}}
            onClick={() => {setmarkCompletePopup(false)}}
          >
            Yes, mark complete
          </span>
          <button
            class="button button-primary"
            // onClick={() => handleAcceptRejectArticle(articleId, "accepted")}
            // onClick={() => {setmarkCompletePopup(false), markCompleteHandle(markCompletePopupId)}}
            onClick={() => {setmarkCompletePopup(false)}}
          >
           Go back
          </button>
        </Modal.Footer>
      </Modal>
      <ConfirmationalModal
        setShowReject={setShowReject}
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        title={"Confirmation"}
        body={`<div class="text-center allGoodText01"><p>Are you sure you want to reject this article for editorial assessment?</div></p>`}
        leftBtnTxt={"REJECT"}
        rightBtnTxt={"MY DASHBOARD"}
        peActiveTab={status}
      /> 

      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={showAcceptConfirmation}
        id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      >
        <Modal.Header className="modal-header">
          <h2 class="modal-title">Good to know</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowAcceptConfirmation(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="aaforAss text-center">
            <p className="allGoodText02">
              Are you sure you want to accept this article for editorial
              assessment?
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <span
            className="btn-link-active bold mr-3"
            onClick={() => navigate("/dashboard/publishing-editor")}
          >
            MY DASHBOARD
          </span>
          <button
            class="button button-primary"
            onClick={() => handleAcceptRejectArticle(articleId, "accepted")}
          >
            ACCEPT
          </button>
        </Modal.Footer>
      </Modal>
      <RejectModal
        showReject={showReject}
        setShowReject={setShowReject}
        articleId={articleId}
        userId={id}
        fetchPublishingEditor={fetchPublishingEditor}
        setUpdateRequestList={setUpdateRequestList}
      />
      <Modal
        dialogClassName="modal-dialog-centered modal-md authorModal md-x"
        show={showAccept}
        id="congratsMobile"
        tabIndex={-1}
        className="md-x"
      > <Confetti width={"450px"} height={"350px"} numberOfPieces={100} />
        <Modal.Header className="modal-header">
          <div class="modal-icon">
            {" "}
            <img src={doneSvg} alt="#" />
          </div>
          <h2 class="modal-title">ACCEPTED FOR EDITORIAL ASSESSMENT</h2>
          <button
            type="button"
            class="btn-close"
            ariaLabel="Close"
            onClick={() => setShowAccept(false)}
          ></button>
        </Modal.Header>

        <Modal.Body className="pt-0">
          <div className="aaforAssContent text-left">
            <p>
              Thank You! We truly appreciate you taking the time out to review
              this article.
            </p>
            <p>
              As a thank you gesture, we will add 250 Mudras to your Mudra Kosh
              (Wallet) once the article is published.
            </p>
            <p>
              Please find this article in the{" "}
              <Link to="/publishing-editor/accepted" state={"accepted"}>
                Accepted
              </Link>{" "}
              section of your dashboard.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <button
            class="button button-primary"
            onClick={() => setShowAccept(false)}
          >
            OKAY
          </button>
        </Modal.Footer>
      </Modal>
      <ReasonModal
        showNotAccepted={showNotAccepted}
        setShowNotAccepted={setShowNotAccepted}
        reasonHandle={reasonHandle}
        reasonComments={reasonComments}
        peActiveTab={status}
        _id={articleId}
        rejectionArticleId={rejectionArticleId}
      />

     
      {status === "draft" && (
        <ArticleDetailsModal
          showArticleDetails={showArticleDetails}
          setShowArticleDetails={setShowArticleDetails}
          articleData={articleData}
          currentData={currentData}
          userDetail={userDetail}
          status={"Publishing Editor"}
        />
      )}
    </>
  );
};

export default PublishingEditor;
