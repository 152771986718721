import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import ArticleListing from "../../../components/dashboard/ArticleListing";
import "./index.scss";
import downarrow from "../../../assets/img/icons/downarrow.svg";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Modal from "react-bootstrap/Modal";
import DashboardLayout from "../../../layout/dashboard";
import {
  getArticleDetails,
  getPEDetails,
} from "../../../store/apiCalls/dashboard";
import PEListing from "./PEListing";
import { useSelector, useDispatch } from "react-redux";
import RecommendedArticles from "../dashboard/RecommendedArticles";

const EditorDashboard = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.LoginReducer);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [updateRequestList, setUpdateRequestList] = useState(false);
  const [amendmentUpdateTrigger, setAmendmentUpdateTrigger] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const { id } = jwt_decode(localStorage.usertoken);
  const [activeTab, setActiveTab] = useState("draft");
  const [peActiveTab, setPEActiveTab] = useState("draft");
  const [peData, setPEData] = useState([]);
  const [totalPEData, setTotalPEData] = useState([]);
  const [articleData, setArticleData] = useState([]);
  const [articleTotalData, setArticleTotalData] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 3;
  const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
  const [collapseActive, setIsActive] = useState(false);
  const expandClick = (event) => {
    setIsActive((current) => !current);
  };

  const fetchPublishingEditor = async (tab) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const params = {
      userId: id,
      page: page,
      limit,
      peStatus: tab,
    };
    const response = await getPEDetails(headers, params);
    if (response?.status === 200) {
      dispatch({ type: "SET_LOADER", payload: false });
      setPEData(response?.data?.data?.data);
      setTotalPEData(response?.data?.data);
    } else {
      dispatch({ type: "SET_LOADER", payload: true });
      return toast.error("Please try again");
    }
  };

  const getPEType = (type) => {
    fetchPublishingEditor(type);
    setPEActiveTab(type);
  };

  const fetchArticleData = async (tab) => {
    dispatch({ type: "SET_LOADER", payload: true });
    const params = {
      userId: id,
      status: tab,
      page: page,
      limit: limit,
    };
    const response = await getArticleDetails(headers, params);
    if (response?.status === 200) {
      dispatch({ type: "SET_LOADER", payload: false });
      setArticleData(response?.data?.data?.article);
      setArticleTotalData(response?.data?.data);
    } else {
      dispatch({ type: "SET_LOADER", payload: false });
      return toast.error("Please try again");
    }
  };

  const getArticleType = (type) => {
    fetchArticleData(type);
    setActiveTab(type);
  };

  useEffect(() => {
    if (updateTrigger) {
      fetchArticleData("published");
    } else if (amendmentUpdateTrigger) {
      fetchArticleData("published")
    } else {
      fetchArticleData("draft");
    }
    localStorage.setItem("getPEType", "");
    fetchPublishingEditor("draft");
    setIsActive(true);
  }, [page, updateTrigger, amendmentUpdateTrigger, updateRequestList]);
  const profileAs= localStorage.getItem("dropdownToggleVal");

  return (
    <>
      <DashboardLayout>
        <>
          <div className="text-initial">
            <div className="pedashboardBox">
              <div className="pedashboardLeft">
                <p className="welcome">
                  Welcome,{" "}{user?.fullName.charAt(0).toUpperCase() + user.fullName.slice(1)}
                </p>
                <div className="articleWrap">
                  <div className="articleLeft">
                  <h2>You are logged in as the {profileAs=="societyEditor"?"Society Editor":null}</h2>
                  </div>
                </div>
              </div>
              <div className="pedashboardMidd">
                <ul className="reviewGray">
                  <li>
                    <span>Sent for Review</span>
                    <b>{totalPEData?.numberOfSentToReviewRecord}</b>
                  </li>
                  <li>
                    <span>Approved</span>
                    <b>{totalPEData?.numberOfApprovedRecord}</b>
                  </li>
                  <li>
                    <span>Sent for Revision</span>
                    <b>{totalPEData?.numberOfSentForRevision}</b>
                  </li>
                </ul>
              </div>
            </div>

            <div className="articleTabsWrap mt-0 pt-0 border-0">
              <h3 className="commanTitle">For Your Review</h3>
              <div className="customTabs">
                <Tab.Container defaultActiveKey="requests">
                  <Nav>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="requests"
                        onClick={() => [
                          setPEActiveTab("draft"),
                          getPEType("draft"),
                        ]}
                      >
                        New Submission
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="notAccepted"
                        onClick={() => [
                          setPEActiveTab("notAccepted"),
                          getPEType("notAccepted"),
                        ]}
                      >
                       Editor Rejected
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="accepted"
                        onClick={() => [
                          setPEActiveTab("accepted"),
                          getPEType("accepted"),
                        ]}
                      >
                        Editor Accepted
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="sendForReview"
                        onClick={() => [
                          setPEActiveTab("sendForReview"),
                          getPEType("sendForReview"),
                        ]}
                      >
                        Sent for Review
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="peerReview"
                        onClick={() => [
                          setPEActiveTab("peerReview"),
                          getPEType("peerReview"),
                        ]}
                      >
                        Peer Review Result
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="secondReview"
                        onClick={() => [
                          setPEActiveTab("secondReview"),
                          getPEType("secondReview"),
                        ]}
                      >
                        Second Review
                      </Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                      <Nav.Link
                        eventKey="approved"
                        onClick={() => [
                          setPEActiveTab("approved"),
                          getPEType("approved"),
                        ]}
                      >
                        Approved
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link
                        eventKey="sentForRevision"
                        onClick={() => [
                          setPEActiveTab("sentForRevision"),
                          getPEType("sentForRevision"),
                        ]}
                      >
                        Revision
                      </Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link
                        eventKey="rejected"
                        onClick={() => [
                          setPEActiveTab("rejected"),
                          getPEType("rejected"),
                        ]}
                      >
                        Rejected
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="published"
                        onClick={() => [
                          setPEActiveTab("published"),
                          getPEType("published"),
                        ]}
                      >
                        Published
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey="requests">
                      {peActiveTab === "draft" && (
                        <PEListing
                          peData={peData}
                          peActiveTab={peActiveTab}
                          fetchPublishingEditor={fetchPublishingEditor}
                          totalNoOfRecords={
                            totalPEData?.numberRecordBaseOnFilter
                          }
                          setUpdateRequestList={setUpdateRequestList}
                        />
                      )}
                    </Tab.Pane>

                    <Tab.Pane eventKey="accepted">
                      {peActiveTab === "accepted" && (
                        <PEListing
                          peData={peData}
                          peActiveTab={peActiveTab}
                          fetchPublishingEditor={fetchPublishingEditor}
                          totalNoOfRecords={
                            totalPEData?.numberRecordBaseOnFilter
                          }
                        />
                      )}
                    </Tab.Pane>

                    <Tab.Pane eventKey="sendForReview">
                      {peActiveTab === "sendForReview" && (
                        <PEListing
                          peData={peData}
                          peActiveTab={peActiveTab}
                          fetchPublishingEditor={fetchPublishingEditor}
                          totalNoOfRecords={
                            totalPEData?.numberRecordBaseOnFilter
                          }
                        />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="secondReview">
                      {peActiveTab === "secondReview" && (
                        <PEListing
                          peData={peData}
                          peActiveTab={peActiveTab}
                          fetchPublishingEditor={fetchPublishingEditor}
                          totalNoOfRecords={
                            totalPEData?.numberRecordBaseOnFilter
                          }
                        />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="peerReview">
                      {peActiveTab === "peerReview" && (
                        <PEListing
                          peData={peData}
                          peActiveTab={peActiveTab}
                          fetchPublishingEditor={fetchPublishingEditor}
                          totalNoOfRecords={
                            totalPEData?.numberRecordBaseOnFilter
                          }
                        />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="approved">
                      {peActiveTab === "approved" && (
                        <PEListing
                          peData={peData}
                          peActiveTab={peActiveTab}
                          fetchPublishingEditor={fetchPublishingEditor}
                          totalNoOfRecords={
                            totalPEData?.numberRecordBaseOnFilter
                          }
                        />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="sentForRevision">
                      {peActiveTab === "sentForRevision" && (
                        <PEListing
                          peData={peData}
                          peActiveTab={peActiveTab}
                          fetchPublishingEditor={fetchPublishingEditor}
                          totalNoOfRecords={
                            totalPEData?.numberRecordBaseOnFilter
                          }
                        />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="rejected">
                      {peActiveTab === "rejected" && (
                        <PEListing
                          peData={peData}
                          peActiveTab={peActiveTab}
                          fetchPublishingEditor={fetchPublishingEditor}
                          totalNoOfRecords={
                            totalPEData?.numberRecordBaseOnFilter
                          }
                        />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="notAccepted">
                      {peActiveTab === "notAccepted" && (
                        <PEListing
                          peData={peData}
                          peActiveTab={peActiveTab}
                          fetchPublishingEditor={fetchPublishingEditor}
                          totalNoOfRecords={
                            totalPEData?.numberRecordBaseOnFilter
                          }
                        />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="published">
                      {peActiveTab === "published" && (
                        <PEListing
                          peData={peData}
                          peActiveTab={"approved"}
                          fetchPublishingEditor={fetchPublishingEditor}
                          totalNoOfRecords={
                            totalPEData?.numberRecordBaseOnFilter
                          }
                        />
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>

              <div className="innerTabsView">
                <div className="preReview">
                  <span>My Articles</span>
                </div>

                <div className="customTabs">
                  <div className="articleTabsWrap mt-3 pt-0 border-0">
                    <div className="customTabs">
                      <Tab.Container defaultActiveKey="draft">
                        <div className="tabsBoxin">
                          <Nav>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="draft"
                                onClick={() => [
                                  setActiveTab("draft"),
                                  getArticleType("draft"),
                                ]}
                              >
                                Drafts
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="underReview"
                                onClick={() => [
                                  setActiveTab("submit"),
                                  getArticleType("submit"),
                                ]}
                              >
                                Under Review
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="approved"
                                onClick={() => [
                                  setActiveTab("approved"),
                                  getArticleType("approved"),
                                ]}
                              >
                                Approved
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="rejected"
                                onClick={() => [
                                  setActiveTab("rejected"),
                                  getArticleType("rejected"),
                                ]}
                              >
                                Rejected
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="published"
                                onClick={() => [
                                  setActiveTab("published"),
                                  getArticleType("published"),
                                ]}
                              >
                                Published
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <button
                            className={collapseActive ? "colapseBtn" : ""}
                            onClick={expandClick}
                          >
                            {collapseActive ? "Collapse" : "Expand"}
                            <i>
                              <img src={downarrow} alt="#" />
                            </i>
                          </button>
                        </div>
                        <div className={collapseActive ? "show" : "hide"}>
                          <div className="subReviewGray mt-4">
                            <ul className="reviewGray">
                              <li>
                                <span>Under Review</span>
                                <b>{articleTotalData?.numberOfSubmitRecords || 0}</b>
                              </li>
                              <li>
                                <span>Approved</span>
                                <b>
                                  {articleTotalData?.numberOfApprovedRecords || 0}
                                </b>
                              </li>
                              <li>
                                <span>Published</span>
                                <b>
                                  {articleTotalData?.numberOfPublishedRecords || 0}
                                </b>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <Tab.Content
                          className={collapseActive ? "show" : "hide"}
                        >
                          <Tab.Pane eventKey="draft">
                            {activeTab === "draft" && (
                              <ArticleListing
                                articleData={articleData}
                                activeTab={activeTab}
                                totalRecords={
                                  articleTotalData?.numberRecordBaseOnFilter
                                }
                              />
                            )}
                          </Tab.Pane>

                          <Tab.Pane eventKey="underReview">
                            {activeTab === "submit" && (
                              <ArticleListing
                                articleData={articleData}
                                activeTab={activeTab}
                                totalRecords={
                                  articleTotalData?.numberRecordBaseOnFilter
                                }
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey="approved">
                            {activeTab === "approved" && (
                              <ArticleListing
                                articleData={articleData}
                                activeTab={activeTab}
                                totalRecords={
                                  articleTotalData?.numberRecordBaseOnFilter
                                }
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey="rejected">
                            {activeTab === "rejected" && (
                              <ArticleListing
                                articleData={articleData}
                                activeTab={activeTab}
                                totalRecords={
                                  articleTotalData?.numberRecordBaseOnFilter
                                }
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey="published">
                            {activeTab === "published" && (
                              <ArticleListing
                                articleData={articleData}
                                activeTab={activeTab}
                                totalRecords={
                                  articleTotalData?.numberRecordBaseOnFilter
                                }
                                setAmendmentUpdateTrigger={setAmendmentUpdateTrigger}
                                setUpdateTrigger={setUpdateTrigger}
                              />
                            )}
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        <Modal
          show={show}
          onHide={handleClose}
          dialogClassName="modal-dialog-centered modal-lg authorModal"
        >
          <Modal.Header className="modal-header">
            <h5 className="modal-title">Grant Details</h5>
            <button
              type="button"
              className="btn-close"
              ariaLabel="Close"
              onClick={(e) => handleOTPClose(e)}
            />
          </Modal.Header>
          <Modal.Body className="pb-0">
            <div className="grantDetails">
              <div className="row">
                <div className="col-md-6">
                  <div className="infotext">
                    <b>Grant Name</b>
                    <span className="textGreen">
                      Vikramshila Grant for Authors
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="infotext">
                    <b>Available for</b>
                    <span className="textGreen">
                      Vikramshila Grant for Authors
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="infotext">
                    <b>URL of Grant available for </b>
                    <span className="textBlue">
                      Urban climate change management and society
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="infotext">
                    <b>Description</b>
                    <span className="textGray">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis labore et dolore
                      magna aliqua labore et dolore magna...
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="infotext">
                    <b>Eligibility</b>
                    <ul className="listText">
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </li>
                      <li>
                        Sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="infotext">
                    <b>Know More</b>
                    <ul className="knowList">
                      <li>
                        <p>
                          <i>Grant Received On</i>
                          <small>12 May 2023</small>
                        </p>
                        <p>
                          <i>Valid Till</i>
                          <small>25 Aug 2023</small>
                        </p>
                      </li>

                      <li>
                        <p>
                          <i>Amount Received</i>
                          <small>INR 7000</small>
                        </p>
                        <p>
                          <i>Amount Remaining</i>
                          <small>INR 6500</small>
                        </p>
                      </li>
                      <li>
                        <p>
                          <i>Transaction ID</i>
                          <small>826475844855</small>
                        </p>
                        <p>
                          <i>Invoice</i>
                          <div className="downloadBOx">
                            <a href="" download></a>
                            <strong>Download</strong>
                          </div>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="pb-4">
            <button
              class="button button-primary"
              type="submit"
              onClick={handleClose}
            >
              CLOSE
            </button>
          </Modal.Footer>
        </Modal>
      </DashboardLayout>
      
    </>
  );
};
export default EditorDashboard;
